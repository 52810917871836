<template>
  <div data-class="list">
    <BaseSearchListPage
      :breadcrumbItems="breadcrumbItems"
      :searchCondition="searchCondition"
      :searchConditionInfo="searchConditionInfo"
      :buttonItems="buttonItems"
      :tableItems="tableItems"
      :fields="fields"
      selectMode="range"
      @row-selected="onRowSelected"
      @max-count-changed="onMaxCountChanged"
      @clear="clear(obj)"
      @search="search(obj)"
    >
    </BaseSearchListPage>

    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="department-modal"
      type="entry"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <DepartmentModal
      id="carrier-modal"
      type="entry"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartments"
      @after-close-unset="afterCloseUnsetDepartments"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 商品マスタモーダル -->
    <ProductModal
      id="product-modal"
      type="entry"
      @after-close-set="afterCloseSetProduct"
      @after-close-unset="afterCloseUnsetProduct"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 場所モーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 削除モーダル -->
    <ConfirmModal
      id="remove-modal"
      :columnInfos="messageInfo"
      :message="removeMessage"
      @confirm="remove(obj)"
    />

    <!-- 一括確認モーダル -->
    <ConfirmModal
      id="allconfirm-modal"
      :columnInfos="messageInfo"
      :message="removeMessage"
      @confirm="allConfirm(obj)"
    />

    <!-- 承認モーダル -->
    <ConfirmModal
      id="approve-modal"
      :columnInfos="messageInfo"
      :message="removeMessage"
      @confirm="batchApprove(obj)"
    />

    <!-- CSV出力モーダル -->
    <ConfirmModal
      id="download-modal"
      :columnInfos="messageInfo"
      :message="downloadMessage"
      @confirm="download(obj)"
    />
    <!-- CSV取込モーダル -->
    <UploadModal
      id="upload-modal"
      :columnInfos="fileInfo"
      @upload="setUploadFile"
    />

    <!-- 変更ロックモーダル -->
    <ConfirmModal
      id="batch-lock-modal"
      :columnInfos="messageInfo"
      :message="removeMessage"
      @confirm="batchLock(obj)"
    />

    <!-- ロック解除モーダル -->
    <ConfirmModal
      id="batch-unlock-modal"
      :columnInfos="messageInfo"
      :message="removeMessage"
      @confirm="batchUnlock(obj)"
    />
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  openSubModal,
} from '@/common/Common.js'
import { SCREEN_ID, DEPARTMENT_KIND } from '@/common/const.js'
import {
  init,
  reSearch,
  search,
  clear,
  goDetail,
  goNew,
  goCopy,
  goRemove,
  goAllConfirm,
  goApprove,
  goBatchLock,
  goBatchUnlock,
  download,
  upload,
  remove,
  allConfirm,
  batchApprove,
  batchLock,
  batchUnlock,
} from '@/accounting/payment/PaymentList.js'

const screenId = SCREEN_ID.PAYMENT_LIST

export default {
  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    ProductModal: () => import('@/master/product/ProductModal.vue'),
  },

  props: {
    isReSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableItems: [],
      selectedItem: [],
      placeModalKbn: 0,
      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      obj: this,
      removeMessage: null,
      //approveMessage: null, //使用してない。
      cityModalSearchCondition: {},
      departmentModalSearchCondition: {},
      printKbn: '',
    }
  },

  created() {
    init(this)
  },

  computed: {
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        screenId
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        inputComponents: [
          {
            // 期間
            type: 'select',
            id: 'period',
            columnInfo: searchConditionInfo.period,
            options: getListOptions(
              this.MASTER_CODE.PAYMENT_PERIOD_CODE,
              false
            ),
            rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            onChangeCallback: () => {},
          },
          {
            // 期間From - To
            type: 'dateFromTo',
            fromId: 'dateFrom',
            toId: 'dateTo',
            fromColumnInfo: searchConditionInfo.date_from,
            toColumnInfo: searchConditionInfo.date_to,
            locale: this.locale,
            fromRules: [this.INPUT_TYPE_CHECK.DATERANGE],
          },
          {
            // 請求支払ステータス
            type: 'select',
            id: 'costStatus',
            columnInfo: searchConditionInfo.cost_status,
            options: getListOptions(this.MASTER_CODE.COST_STATUS),
            onChangeCallback: () => {},
          },
          {
            // 荷主
            type: 'referenceCodeName',
            codeId: 'ownerCode',
            nameId: 'ownerName',
            codeColumnInfo: searchConditionInfo.owner_code,
            nameColumnInfo: searchConditionInfo.owner_name,
            btnColumnInfo: searchConditionInfo.sub_modal_owner,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.departmentModalSearchCondition = {
                departmentKind: DEPARTMENT_KIND.OWNER,
              }
              this.openSubModal(this, 'department-modal')
            },
          },
          {
            // 商品
            type: 'referenceCode',
            codeId: 'productName',
            codeColumnInfo: searchConditionInfo.product_name,
            btnColumnInfo: searchConditionInfo.sub_modal_product,
            onClickCallback: () => {
              this.openSubModal(this, 'product-modal')
            },
          },
          {
            // 出発場所
            type: 'referenceCode',
            codeId: 'departurePlaceName',
            codeColumnInfo: searchConditionInfo.departure_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_departure_place,
            codeMaxLength: 50,
            onClickCallback: () => {
              //...ボタン
              this.placeModalKbn = 1
              this.openSubModal(this, 'place-modal')
            },
          },
          {
            // 到着場所
            type: 'referenceCode',
            codeId: 'arrivalPlaceName',
            codeColumnInfo: searchConditionInfo.arrival_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_arrival_place,
            codeMaxLength: 50,
            onClickCallback: () => {
              this.placeModalKbn = 2
              this.openSubModal(this, 'place-modal')
            },
          },
          {
            // 予備１（機種）
            type: 'text',
            id: 'reserve01',
            columnInfo: searchConditionInfo.reserve01,
          },
          {
            // 予備２（機番）
            type: 'text',
            id: 'reserve02',
            columnInfo: searchConditionInfo.reserve02,
          },
          {
            // 予備３（受付番号）
            type: 'text',
            id: 'reserve03',
            columnInfo: searchConditionInfo.reserve03,
          },
          {
            // 予備10（配車結果番号）
            type: 'text',
            id: 'reserve10',
            columnInfo: searchConditionInfo.reserve10,
          },
          {
            // 経費ID
            type: 'text',
            id: 'accountingId',
            columnInfo: searchConditionInfo.accounting_id,
          },
          {
            // 協力会社(運送会社)
            type: 'referenceCodeName',
            codeId: 'carrierCode',
            nameId: 'carrierName',
            codeColumnInfo: searchConditionInfo.carrier_code,
            nameColumnInfo: searchConditionInfo.carrier_name,
            btnColumnInfo: searchConditionInfo.sub_modal_owner,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.departmentModalSearchCondition = {
                departmentKind: DEPARTMENT_KIND.CARRIER,
              }
              this.openSubModal(this, 'carrier-modal')
            },
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
    fileInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'file'
      )
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return [
        {
          name: this.$store.getters['init/getScreenName'](screenId),
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 詳細ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.detail,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goDetail(this)
            },
          },
          // 新規ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.new,
            onClickCallback: () => {
              goNew()
            },
          },
          // コピーボタン
          {
            variant: 'primary',
            icon: 'copy',
            columnInfo: this.resultControlInfo.copy,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goCopy(this)
            },
          },
          // 削除ボタン
          {
            variant: 'primary',
            icon: 'times',
            columnInfo: this.resultControlInfo.delete,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goRemove(this)
            },
          },
          // 一括確認ボタン
          {
            variant: 'primary',
            icon: 'list',
            columnInfo: this.resultControlInfo.all_confirm,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goAllConfirm(this)
            },
          },
          // 承認ボタン
          {
            variant: 'primary',
            icon: 'list',
            columnInfo: this.resultControlInfo.approve,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goApprove(this)
            },
          },
          // CSVﾌｧｲﾙ出力ボタン
          {
            variant: 'primary',
            icon: 'download',
            columnInfo: this.resultControlInfo.file_print,
            onClickCallback: () => {
              this.printKbn = '' //CSV
              openSubModal(this, 'download-modal')
            },
          },
          // ﾌｧｲﾙ取込ボタン
          {
            variant: 'primary',
            icon: 'upload',
            columnInfo: this.resultControlInfo.file_read,
            onClickCallback: () => {
              openSubModal(this, 'upload-modal')
            },
          },
        ],
        // ボタングループ#2
        [
          // 変更ロックボタン #2781
          {
            variant: 'primary',
            icon: 'times',
            columnInfo: this.resultControlInfo.batch_lock,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goBatchLock(this)
            },
          },
          // ロック解除ボタン #2781
          {
            variant: 'primary',
            icon: 'times',
            columnInfo: this.resultControlInfo.batch_unlock,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goBatchUnlock(this)
            },
          },
          // 請求ファイル出力ボタン
          {
            variant: 'primary',
            icon: 'download',
            columnInfo: this.resultControlInfo.sales_print,
            onClickCallback: () => {
              this.printKbn = 'S'
              openSubModal(this, 'download-modal')
            },
          },
          // 支払ファイル出力ボタン
          {
            variant: 'primary',
            icon: 'download',
            columnInfo: this.resultControlInfo.purchase_print,
            onClickCallback: () => {
              this.printKbn = 'P'
              openSubModal(this, 'download-modal')
            },
          },
        ],
      ]
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelected() {
      return this.selectedItem.length > 0
    },
    /**
     * メッセージ.
     * @return {String}
     */
    downloadMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG994Q',
        this.resultControlInfo.file_print.label
      )
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    reSearch,
    search,
    clear,
    remove,
    goCopy,
    download,
    upload,
    allConfirm,
    batchApprove,
    batchLock,
    batchUnlock,
    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      let today = this.$moment(new Date()).format('YYYY-MM-DD')
      return {
        accountingId: null,
        carrierCode: null,
        carrierName: null,
        carrierId: null,
        dateFrom: today,
        dateTo: today,
        costStatus: null,
        ownerCode: null,
        ownerName: null,
        productCode: null,
        productName: null,
        departurePlaceCode: null,
        departurePlaceName: null,
        arrivalPlaceCode: null,
        arrivalPlaceName: null,
        reserve01: null,
        reserve02: null,
        reserve03: null,
        reserve10: null,
        size: getSearchMaxCount(),
        screenId: screenId,
        domainName: 'result_fields',
        period: '10',
      }
    },

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      this.searchCondition.ownerCode = code
      this.searchCondition.ownerName = name
    },
    afterCloseSetDepartments(id, code, name) {
      this.searchCondition.carrierCode = code
      this.searchCondition.carrierName = name
      //強制更新
      // document.getElementById('carrier_code').value = code
      // document.getElementById('carrier_name').value = name
    },
    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      this.searchCondition.ownerCode = null
      this.searchCondition.ownerName = null
    },
    afterCloseUnsetDepartments() {
      this.searchCondition.carrierCode = null
      this.searchCondition.carrierName = null
      //強制更新
      // document.getElementById('carrier_code').value = null
      // document.getElementById('carrier_name').value = null
    },
    /**
     * 商品マスタから取得データ設定
     */
    afterCloseSetProduct(selectedItem) {
      this.searchCondition.productCode = selectedItem[0].product_code
      this.searchCondition.productName = selectedItem[0].product_name
    },

    /**
     * 商品情報解除
     */
    afterCloseUnsetProduct() {
      this.searchCondition.productCode = null
      this.searchCondition.productName = null
    },

    /**
     * 場所マスタから取得データ設定
     */
    afterCloseSetPlace(selectedItem) {
      if (this.placeModalKbn == 1) {
        this.searchCondition.departurePlaceCode = selectedItem.place_code
        this.searchCondition.departurePlaceName = selectedItem.place_name
      } else {
        this.searchCondition.arrivalPlaceCode = selectedItem.place_code
        this.searchCondition.arrivalPlaceName = selectedItem.place_name
      }
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetPlace() {
      if (this.placeModalKbn == 1) {
        this.searchCondition.departurePlaceCode = null
        this.searchCondition.departurePlaceName = null
      } else {
        this.searchCondition.arrivalPlaceCode = null
        this.searchCondition.arrivalPlaceName = null
      }
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    setInfoMsg(msg) {
      this.$emit('set-info-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    onMaxCountChanged(maxCount) {
      this.searchCondition.size = maxCount
    },

    setUploadFile(file) {
      upload(this, file)
    },
  },
}
</script>

<style scoped>
#footer {
  bottom: 0;
  position: fixed;
  width: 96.8%;
  height: 50px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: white;
  /* margin-left:300px; */
  text-align: center;
}
</style>
