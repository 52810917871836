import { createAuthenticaionService, makeQueryParams } from '@/services'

const BASE_URL = `${process.env.VUE_APP_API_URL}billings/`

class BillingService {
  getAll() {
    return createAuthenticaionService(BASE_URL).get('/')
  }

  getByAccounting(id) {
    return createAuthenticaionService(BASE_URL).get(`/${id}`)
  }

  getCostByBilling(id) {
    return createAuthenticaionService(BASE_URL).get(`/cost/${id}`)
  }

  search(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}search/`).get(`/?${param}`)
  }

  download(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}download/`).get(`/?${param}`)
  }
}

export default new BillingService()
