import { createAuthenticaionService, makeQueryParams } from '@/services'

const BASE_URL = `${process.env.VUE_APP_API_URL}fare/`

class FareService {
  // getAll() {
  //   return createAuthenticaionService(BASE_URL).get('/')
  // }

  search(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}search/`).get(`/?${param}`)
  }

  download(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}download/`).get(`/?${param}`)
  }

  acceptance(datas) {
    return createAuthenticaionService(`${BASE_URL}acceptance/`).put('/', datas)
  }
}

export default new FareService()
