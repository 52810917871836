import {
  openMsgModal,
  displaYyyymmdd,
  formatYYYYMMDD,
  csvFileName,
  downloadCSV,
} from '@/common/Common.js'
import { SCREEN_ID, MSG_TYPE, DETAIL_SCREEN_KBN } from '@/common/const.js'
import router from '@/router'
import BillingService from './BillingService'

const screenId = SCREEN_ID.BILLING_LIST
const detailScreenId = SCREEN_ID.BILLING_DETAIL

export function init(obj) {
  // サーバーの処理後再検索
  if (obj.isReSearch == true) {
    reSearch(obj)
  }
}

export function reSearch(obj) {
  var searchCondition = obj.$store.getters['search/getSearchCondition'](
    screenId
  )
  if (searchCondition != null) {
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

/**
 * 検索
 * @param {*} obj
 */
export function search(obj) {
  obj.searchCondition.periodDateFrom = formatYYYYMMDD(
    obj,
    obj.searchCondition.periodDateFrom
  )
  obj.searchCondition.periodDateTo = formatYYYYMMDD(
    obj,
    obj.searchCondition.periodDateTo
  )

  obj.$store.dispatch('init/setServiceLoading')
  BillingService.search(obj.searchCondition)
    .then(
      (response) => {
        obj.tableItems = response.data.resultList

        obj.tableItems.forEach((element) => {
          // 出発開始
          element.departure_date = displaYyyymmdd(obj, element.departure_date)
          // 到着開始
          element.arrival_date = displaYyyymmdd(obj, element.arrival_date)
          // 請求日
          element.billing_date = displaYyyymmdd(obj, element.billing_date)
        })
        if (obj.tableItems.length < 1) {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        }
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        // エラーモーダル表示
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

/**
 * 検索項目をクリアします.
 */
export function clear(obj) {
  obj.searchCondition = obj.defaultSearchCondition()
  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)

  // search condition validate reset
  obj.$refs['result-search'].$refs['base-search'].$refs[
    'base-search-condition'
  ].reset()
}

// 「詳細」ボタンクリック
export function goDetail(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        orderDetailId: obj.selectedItem[0].order_detail_id,
        accountingId: obj.selectedItem[0].accounting_id,
        screenKbn: DETAIL_SCREEN_KBN.DETAIL,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}

// 「CSV出力」ボタンクリック
export function download(obj) {
  obj.$bvModal.hide('download-modal')

  obj.searchCondition.periodDateFrom = formatYYYYMMDD(
    obj,
    obj.searchCondition.periodDateFrom
  )
  obj.searchCondition.periodDateTo = formatYYYYMMDD(
    obj,
    obj.searchCondition.periodDateTo
  )

  obj.searchCondition.salesType = this.printKbn
  obj.searchCondition.filename = csvFileName(obj, screenId)

  obj.$store.dispatch('init/setServiceLoading')
  BillingService.download(obj.searchCondition)
    .then(
      (response) => {
        if (response.data == '') {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        } else {
          downloadCSV(obj.searchCondition.filename, response.data)
        }
      },
      (e) => {
        // エラーモーダル表示
        let msg
        if (e.response === undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, obj.MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}
