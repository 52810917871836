<template>
  <div data-class="list">
    <BaseSearchListPage
      ref="result-search"
      :breadcrumbItems="breadcrumbItems"
      :searchCondition="searchCondition"
      :searchConditionInfo="searchConditionInfo"
      :buttonItems="buttonItems"
      :tableItems="tableItems"
      :fields="fields"
      selectMode="range"
      @row-selected="onRowSelected"
      @max-count-changed="onMaxCountChanged"
      @clear="clear(obj)"
      @search="search(obj)"
    >
    </BaseSearchListPage>

    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="department-modal"
      type="entry"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 実車番マスタモーダル -->
    <RealVehicleModal
      id="real-vehicle-modal-four"
      type="entry"
      displayModals="four"
      :selectedSearchCondition="realVehicleModalSearchCondition"
      @after-close-set="afterCloseSetRealVehicle"
      @after-close-unset="afterCloseUnsetRealVehicle"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 商品マスタモーダル -->
    <ProductModal
      id="product-modal"
      type="entry"
      @after-close-set="afterCloseSetProduct"
      @after-close-unset="afterCloseUnsetProduct"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- CSV出力モーダル -->
    <ConfirmModal
      id="download-modal"
      :columnInfos="messageInfo"
      :message="downloadMessage"
      @confirm="download(obj)"
    />

    <!-- 情報モーダル -->
    <InfoModal
      id="detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
    />
    <!-- @after-close="back" -->
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  openSubModal,
} from '@/common/Common.js'
import {
  SCREEN_ID,
  DEPARTMENT_KIND,
  REAL_VEHICLE_KIND_SEARCH,
} from '@/common/const.js'
import {
  init,
  search,
  clear,
  download,
  goAcceptance,
} from '@/accounting/fare/FareList.js'

const screenId = SCREEN_ID.FARE_LIST

export default {
  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    RealVehicleModal: () => import('@/master/realVehicle/RealVehicleModal.vue'),
    ProductModal: () => import('@/master/product/ProductModal.vue'),
  },
  props: {
    isReSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableItems: [],
      selectedItem: [],
      placeModalKbn: 0,
      departmentModalSearchCondition: {},
      realVehicleModalSearchCondition: {},
      realVehicleModalKbn: -1,
      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      obj: this,
      removeMessage: null,
    }
  },

  created() {
    init(this)
  },

  computed: {
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        screenId
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        inputComponents: [
          {
            // 運送会社
            type: 'referenceCodeName',
            codeId: 'carrierCode',
            nameId: 'carrierName',
            codeColumnInfo: searchConditionInfo.carrier_code,
            nameColumnInfo: searchConditionInfo.carrier_name,
            btnColumnInfo: searchConditionInfo.sub_modal_carrier,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.departmentModalSearchCondition = {
                departmentKind: DEPARTMENT_KIND.CARRIER,
              }
              this.openSubModal(this, 'department-modal')
            },
          },
          {
            // 出発日
            type: 'dateFromTo',
            fromId: 'departureDateFrom',
            toId: 'departureDateTo',
            fromColumnInfo: searchConditionInfo.departure_date_from,
            toColumnInfo: searchConditionInfo.departure_date_to,
            locale: this.locale,
            fromRules: [this.INPUT_TYPE_CHECK.DATERANGE],
          },
          {
            // 到着日
            type: 'dateFromTo',
            fromId: 'arrivalDateFrom',
            toId: 'arrivalDateTo',
            fromColumnInfo: searchConditionInfo.arrival_date_from,
            toColumnInfo: searchConditionInfo.arrival_date_to,
            locale: this.locale,
            fromRules: [this.INPUT_TYPE_CHECK.DATERANGE],
          },
          {
            // トラック/トラクタ (TR)
            type: 'referenceCode',
            codeId: 'realVehicleNumberplate',
            codeColumnInfo: searchConditionInfo.real_vehicle_numberplate,
            btnColumnInfo: searchConditionInfo.sub_modal_real_vehicle,
            onClickCallback: () => {
              this.realVehicleModalKbn = 1
              this.realVehicleModalSearchCondition = {}
              // ヘッドorトラクタ
              this.realVehicleModalSearchCondition.realVehicleKind =
                REAL_VEHICLE_KIND_SEARCH.TRTC
              this.openSubModal(this, 'real-vehicle-modal-four')
            },
          },
          // {
          //   // 輸送品
          //   type: 'referenceCodeName',
          //   codeId: 'productCode',
          //   nameId: 'productName',
          //   codeColumnInfo: searchConditionInfo.product_code,
          //   nameColumnInfo: searchConditionInfo.product_name,
          //   btnColumnInfo: searchConditionInfo.sub_modal_product,
          //   codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
          //   onClickCallback: () => {
          //     this.openSubModal(this, 'product-modal')
          //   },
          // },
          {
            // 輸送品
            type: 'referenceCode',
            codeId: 'productName',
            codeColumnInfo: searchConditionInfo.product_name,
            btnColumnInfo: searchConditionInfo.sub_modal_product,
            onClickCallback: () => {
              this.openSubModal(this, 'product-modal')
            },
          },
          {
            // 請求支払ステータス
            type: 'checkboxgroup',
            id: 'costStatus',
            columnInfo: searchConditionInfo.cost_status,
            options: getListOptions(this.MASTER_CODE.COST_STATUS, false),
          },
          {
            // 予備１（機種）
            type: 'text',
            id: 'reserve01',
            columnInfo: searchConditionInfo.reserve01,
          },
          {
            // 予備２（機番）
            type: 'text',
            id: 'reserve02',
            columnInfo: searchConditionInfo.reserve02,
          },
          {
            // 受付番号
            type: 'text',
            id: 'reserve03',
            columnInfo: searchConditionInfo.reserve03,
          },
          {
            // ブロック名
            type: 'text',
            id: 'blockName',
            columnInfo: searchConditionInfo.block_name,
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
    fileInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'file'
      )
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return [
        {
          name: this.$store.getters['init/getScreenName'](screenId),
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 検収ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.acceptance,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goAcceptance(this)
            },
          },
        ],
        // ボタングループ#2
        [
          // ﾌｧｲﾙ出力ボタン
          {
            variant: 'primary',
            icon: 'download',
            columnInfo: this.resultControlInfo.file_print,
            onClickCallback: () => {
              openSubModal(this, 'download-modal')
            },
          },
        ],
      ]
    },
    /**
     * メッセージ.
     * @return {String}
     */
    downloadMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG994Q',
        this.resultControlInfo.file_print.label
      )
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.resultControlInfo.acceptance.label
      )
    },
    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelected() {
      return this.selectedItem.length > 0
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    search,
    clear,
    download,

    /**
     * デフォルト検索パラメータを返します.
     * permit_id: null,
     * @return {Object}
     */
    defaultSearchCondition() {
      let today = this.$moment(new Date()).format('YYYY-MM-DD')
      return {
        carrierCode: null,
        carrierName: null,
        departureDateFrom: today,
        departureDateTo: today,
        arrivalDateFrom: today,
        arrivalDateTo: today,
        realVehicleId: null,
        realVehicleNumberplate: null,
        productCode: null,
        productName: null,
        reserve01: null,
        reserve02: null,
        reserve03: null,
        costStatus: null,
        blockName: null,
        size: getSearchMaxCount(),
        screenId: screenId,
        domainName: 'result_fields',
      }
    },

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      this.searchCondition.carrierCode = code
      this.searchCondition.carrierName = name
    },

    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      this.searchCondition.carrierCode = null
      this.searchCondition.carrierName = null
    },

    /**
     * 商品マスタから取得データ設定
     */
    afterCloseSetProduct(selectedItem) {
      this.searchCondition.productCode = selectedItem[0].product_code
      this.searchCondition.productName = selectedItem[0].product_name
    },

    /**
     * 商品情報解除
     */
    afterCloseUnsetProduct() {
      this.searchCondition.productCode = null
      this.searchCondition.productName = null
    },

    /**
     * 実車番マスタから取得データ設定
     */
    afterCloseSetRealVehicle(selectedItem) {
      if (this.realVehicleModalKbn == 1) {
        //トラック/トラクタ(TR)
        this.searchCondition.realVehicleId = selectedItem.real_vehicle_id
        this.searchCondition.realVehicleNumberplate = selectedItem.numberplate
      }
    },

    afterCloseUnsetRealVehicle() {
      if (this.realVehicleModalKbn == 1) {
        //トラック/トラクタ(TR)
        this.searchCondition.realVehicleId = null
        this.searchCondition.realVehicleNumberplate = null
      }
    },
    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    onMaxCountChanged(maxCount) {
      this.searchCondition.size = maxCount
    },
  },
}
</script>

<style scoped></style>
