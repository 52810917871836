import {
  openMsgModal,
  openSubModal,
  displaYyyymmdd,
  formatYYYYMMDD,
  downloadCSV,
  convertCamelCace,
  csvFileName,
} from '@/common/Common.js'
import { SCREEN_ID, MSG_TYPE } from '@/common/const.js'
import FareService from './FareService'
import _ from 'lodash'

const screenId = SCREEN_ID.FARE_LIST

export function init(obj) {
  // サーバーの処理後再検索
  if (obj.isReSearch == true) {
    reSearch(obj)
  }
}

export function reSearch(obj) {
  var searchCondition = obj.$store.getters['search/getSearchCondition'](
    screenId
  )
  if (searchCondition != null) {
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

/**
 * 検索
 * @param {*} obj
 */
export function search(obj) {
  // const validated = obj.$refs['result-search'].$refs['base-search'].$refs[
  //   'base-search-condition'
  // ].validate()
  // if (!validated) return

  let newSearchCondition = _.cloneDeep(obj.searchCondition)
  convertServiceParam(obj, newSearchCondition)
  // 必須チェック
  if (
    (newSearchCondition.departureDateFrom === null ||
      newSearchCondition.departureDateFrom === '') &&
    (newSearchCondition.departureDateTo === null ||
      newSearchCondition.departureDateTo === '') &&
    (newSearchCondition.arrivalDateFrom === null ||
      newSearchCondition.arrivalDateFrom === '') &&
    (newSearchCondition.arrivalDateTo === null ||
      newSearchCondition.arrivalDateTo === '')
  ) {
    // 警告モーダル表示
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG925E')
    )
    return
  }

  obj.$store.dispatch('init/setServiceLoading')
  FareService.search(newSearchCondition)
    .then(
      (response) => {
        obj.tableItems = response.data.resultList

        obj.tableItems.forEach((element) => {
          // 出荷日
          element.departure_date = displaYyyymmdd(obj, element.departure_date)
          // 搬入日
          element.arrival_date = displaYyyymmdd(obj, element.arrival_date)
          // 支払日
          element.payment_date = displaYyyymmdd(obj, element.payment_date)
          // 検収日
          element.acceptance_date = displaYyyymmdd(obj, element.acceptance_date)
        })
        if (obj.tableItems.length < 1) {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        }
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        // エラーモーダル表示
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

/**
 * 検索項目をクリアします.
 */
export function clear(obj) {
  obj.searchCondition = obj.defaultSearchCondition()
  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

/**
 * 「検収」ボタンクリック
 * @param {*} obj
 */
export function goAcceptance(obj) {
  if (obj.selectedItem.length > 0) {
    let datas = _.cloneDeep(obj.selectedItem)

    datas.forEach((element) => {
      // 出荷日
      element.departure_date = formatYYYYMMDD(obj, element.departure_date)
      // 搬入日
      element.arrival_date = formatYYYYMMDD(obj, element.arrival_date)
      // 支払日
      element.payment_date = formatYYYYMMDD(obj, element.payment_date)
      // 検収日
      element.acceptance_date = formatYYYYMMDD(obj, element.acceptance_date)
    })

    datas = convertCamelCace(datas)

    obj.$store.dispatch('init/setServiceLoading')
    FareService.acceptance(datas)
      .then(
        () => {
          // 情報モーダル表示
          openSubModal(obj, 'detail-info-modal')

          // 再検索
          reSearch(obj)
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, obj.MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

// 「CSV出力」ボタンクリック
export function download(obj) {
  obj.$bvModal.hide('download-modal')

  //obj.searchCondition.filename = 'G00310.csv'
  let newCsvName = csvFileName(obj, screenId)
  obj.searchCondition.filename = newCsvName
  let newSearchCondition = _.cloneDeep(obj.searchCondition)
  convertServiceParam(obj, newSearchCondition)
  // 必須チェック
  if (
    (newSearchCondition.departureDateFrom === null ||
      newSearchCondition.departureDateFrom === '') &&
    (newSearchCondition.departureDateTo === null ||
      newSearchCondition.departureDateTo === '') &&
    (newSearchCondition.arrivalDateFrom === null ||
      newSearchCondition.arrivalDateFrom === '') &&
    (newSearchCondition.arrivalDateTo === null ||
      newSearchCondition.arrivalDateTo === '')
  ) {
    // 警告モーダル表示
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG925E')
    )
    return
  }

  obj.$store.dispatch('init/setServiceLoading')
  FareService.download(newSearchCondition)
    .then(
      (response) => {
        if (response.data == '') {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        } else {
          downloadCSV(newSearchCondition.filename, response.data)
        }
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, obj.MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

function convertServiceParam(obj, newSearchCondition) {
  newSearchCondition.departureDateFrom = formatYYYYMMDD(
    obj,
    newSearchCondition.departureDateFrom
  )

  newSearchCondition.departureDateTo = formatYYYYMMDD(
    obj,
    newSearchCondition.departureDateTo
  )

  newSearchCondition.departureDateFrom = formatYYYYMMDD(
    obj,
    newSearchCondition.departureDateFrom
  )

  newSearchCondition.arrivalDateFrom = formatYYYYMMDD(
    obj,
    newSearchCondition.arrivalDateFrom
  )

  newSearchCondition.arrivalDateTo = formatYYYYMMDD(
    obj,
    newSearchCondition.arrivalDateTo
  )
}
