/**
 * 請求支払情報データ.
 * @class
 * @exports
 */
export default class payment {
  //--------------TAB1------------//
  // 経費ID
  accountingId
  // データ作成区分
  dataRegistrationType = null
  // データ作成区分名
  dataRegistrationTypeName = null
  // 請求支払ステータス
  costStatus = null
  // 請求支払ステータス名
  costStatusName = null
  // 運賃手入力区分
  manualInput = null
  // 運賃手入力区分名
  manualInputName = null
  // OBIC連携済みフラグ
  sendFlg = null
  // OBIC連携済みフラグ名
  sendFlgName = null
  // 荷主
  ownerId = null
  ownerCode = null
  // 荷主名
  ownerName = null
  // 荷主の部署グループ
  ownerDepartmentGroup = null
  // 承認依頼コメント
  approvalRequestComment = null
  // 差し戻しコメント
  remandComment = null
  // 商品名
  productId = null
  tariffProductCode = null
  productName = null
  // 数量
  quantity
  // 質量
  weight
  // 容量
  volume
  // 出発場所名
  departurePlaceId = null
  departurePlaceCode = null
  departurePlaceName = null
  // 出発場所住所
  departurePlaceAddress = null
  // 出発日付
  departureDate = null
  // 到着場所名
  arrivalPlaceId = null
  arrivalPlaceCode = null
  arrivalPlaceName = null
  // 到着場所住所
  arrivalPlaceAddress = null
  // 到着日付
  arrivalDate = null
  // 運送会社ID
  carrierId = null
  carrierCode = null
  carrierName = null
  // 輸送手段ID
  vehicleId = null
  vehicleCode = null
  vehicleName = null
  // トラック・トラクタID
  realVehicleId = null
  realVehicleNumberplate
  // トレーラID
  realTrailerId = null
  realTrailerNumberplate = null
  // 作業実績日付
  workActDate = null
  // 実走行距離
  actualDistance
  // 承認日
  approvalDate = null
  // 承認者
  approvalUser = null
  // 承認者名
  approvalUserName = null
  // 往復区分
  onewayOrRound = null
  onewayOrRoundName = null
  // 2台積区分
  isMultipleLoading = null
  isMultipleLoadingName = null
  // 仕向国
  destinationCountry = null

  //実績登録作業区分
  recordWorkKind = null
  recordWorkKindName = null

  // 削除済み区分
  deleted = 0
  // 登録日時
  registrationTimeDisplay = null
  // 登録ユーザ
  registrationUser = null
  // 更新日時
  updateTimeDisplay = null
  // 更新ユーザ
  updateUser = null
  // バージョン
  version = null
  // トリガ更新日時
  updateByTrigger = null
  // 入力状態
  registering = null
  //--------------TAB2------------//
  billingOriginDto = new Object()
  billingId
  billingBlockCode = null
  billingDepartmentId = null
  billingDepartmentCode = null
  billingDepartmentName = null
  billingDate = null
  invoiceDepartmentCode = null
  salesDepartmentCode = null
  inputStaffId = null
  salesStaffId = null
  billingReserve01 = null
  billingReserve02 = null
  billingReserve03 = null
  billingReserve04 = null
  billingReserve05 = null
  billingReserve06 = null
  billingReserve07 = null
  billingReserve08 = null
  billingReserve09 = null
  billingReserve10 = null
  billingSendFlg = '0'

  // 削除済み区分
  billingDeleted = 0
  billingRegistrationTimeDisplay = null
  billingRegistrationUser = null
  billingUpdateTimeDisplay = null
  billingUpdateUser = null
  billingVersion = null
  billingUpdateByTrigger = null

  billingCostDtos = []
  //--------------TAB3------------//
  paymentDto = new Object()
  paymentId
  blockCode = null
  paymentBlockCode = null
  paymentCarrierId = null
  paymentCarrierCode = null
  paymentCarrierName = null
  paymentDate = null
  paymentReserve01 = null
  paymentReserve02 = null
  paymentReserve03 = null
  paymentReserve04 = null
  paymentReserve05 = null
  paymentReserve06 = null
  paymentReserve07 = null
  paymentReserve08 = null
  paymentReserve09 = null
  paymentReserve10 = null
  paymentSendFlg = '0'

  // 削除済み区分
  paymentDeleted = 0
  paymentRegistrationTimeDisplay = null
  paymentRegistrationUser = null
  paymentUpdateTimeDisplay = null
  paymentUpdateUser = null
  paymentVersion = null
  paymentUpdateByTrigger = null

  paymentCostDtos = []

  //--------------TAB3------------//
}
