<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="formData"
          :inputComponents="inputComponents"
        >
          <template v-slot:tab2TableDown>
            <BaseLabel :columnInfo="detailInfo.billing_list" />
            <BaseTable
              select-mode="range"
              ref="table"
              :fields="fieldsBilling"
              :items="tableItemsBilling"
            />
            <BaseLabel :columnInfo="detailInfo.cost_list" />
            <BaseTable
              select-mode="range"
              ref="table"
              :fields="fieldsCost"
              :items="tableItemsCost"
            />
          </template>
        </BaseTabGroup>
      </template>
    </DetailPageLayout>

    <!-- 情報モーダル -->
    <InfoModal
      id="detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
  </div>
</template>

<script>
import BillingInfo from './BillingInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { SCREEN_ID, SLOT_NAME } from '@/common/const.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'

import { init, getCosts } from '@/accounting/billing/BillingDetail.js'

const screenId = SCREEN_ID.BILLING_DETAIL

export default {
  name: 'billingDetail',
  components: {},
  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    orderDetailId: {
      type: Number,
      default: -1,
    },
    accountingId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      formData: new BillingInfo(),
      obj: this,
      oriData: new BillingInfo(),
      tableItemsBilling: [],
      tableItemsCost: [],
      selectedBilling: [],
    }
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](screenId)('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(this.beforeScreenRouteInfo, screenId)
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },
    inputComponents() {
      return [
        {
          // 基本情報タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 受付番号
              type: 'text',
              id: 'receiptNo',
              columnInfo: this.detailInfo.receipt_no,
            },
            {
              // 荷主名
              type: 'text',
              id: 'ownerDepartmentName',
              columnInfo: this.detailInfo.owner_department_name,
            },
            {
              // 記名符号
              type: 'text',
              id: 'description01',
              columnInfo: this.detailInfo.description01,
            },
            {
              // 機番
              type: 'text',
              id: 'description02',
              columnInfo: this.detailInfo.description02,
            },
            {
              // 国内海外区分
              type: 'text',
              id: 'description03',
              columnInfo: this.detailInfo.description03,
            },
            {
              // 仕向国コード
              type: 'text',
              id: 'destinationCountry',
              columnInfo: this.detailInfo.destination_country,
            },
            {
              // 仕向国略称
              type: 'text',
              id: 'reserve13',
              columnInfo: this.detailInfo.reserve13,
            },
            {
              // 出発開始日
              type: 'text',
              id: 'departureDate',
              columnInfo: this.detailInfo.departure_date,
            },
            {
              // 出発場所ID
              type: 'text',
              id: 'departurePlaceId',
              columnInfo: this.detailInfo.departure_place_id,
            },
            {
              // 出発場所名
              type: 'text',
              id: 'departurePlaceName',
              columnInfo: this.detailInfo.departure_place_name,
            },
            {
              // 出荷場所住所
              type: 'text',
              id: 'departurePlaceAddress',
              columnInfo: this.detailInfo.departure_place_address,
            },
            {
              // 到着開始日
              type: 'text',
              id: 'arrivalDate',
              columnInfo: this.detailInfo.arrival_date,
            },
            {
              // 到着場所ID
              type: 'text',
              id: 'arrivalPlaceId',
              columnInfo: this.detailInfo.arrival_place_id,
            },
            {
              //到着場所名
              type: 'text',
              id: 'arrivalPlaceName',
              columnInfo: this.detailInfo.arrival_place_name,
            },
            {
              //到着先住所
              type: 'text',
              id: 'arrivalPlaceAddress',
              columnInfo: this.detailInfo.arrival_place_address,
            },
            {
              //商品名
              type: 'text',
              id: 'productName',
              columnInfo: this.detailInfo.product_name,
            },
            {
              //幅
              type: 'text',
              id: 'reserve08',
              columnInfo: this.detailInfo.reserve08,
            },
            {
              //高さ
              type: 'text',
              id: 'reserve09',
              columnInfo: this.detailInfo.reserve09,
            },
            {
              //長さ
              type: 'text',
              id: 'reserve07',
              columnInfo: this.detailInfo.reserve07,
            },
            {
              //オーダ距離
              type: 'text',
              id: 'actualDistance',
              columnInfo: this.detailInfo.actual_distance,
            },
            {
              //質量
              type: 'text',
              id: 'weight',
              columnInfo: this.detailInfo.weight,
            },
            {
              //区間分割
              type: 'text',
              id: 'sectionPartitionFlag',
              columnInfo: this.detailInfo.section_partition_flag,
            },
            {
              //トラック/トレーラー
              type: 'text',
              id: 'realVehicleKind',
              columnInfo: this.detailInfo.real_vehicle_kind,
            },
            {
              //二台積
              type: 'text',
              id: 'isMultipleLoading',
              columnInfo: this.detailInfo.is_multiple_loading,
            },
            {
              //往路/復路
              type: 'text',
              id: 'goOrBack',
              columnInfo: this.detailInfo.go_or_back,
            },
            {
              //請求日
              type: 'text',
              id: 'billingDate',
              columnInfo: this.detailInfo.billing_date,
            },
          ],
        },
        {
          // 請求情報タブ
          label: this.detailInfo.tab2.label,
          slot: SLOT_NAME.TAB2_TABLE_DOWN,
          inputComponents: [],
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fieldsBilling() {
      return this.$store.getters['init/getFields'](screenId)(
        'result_fields_billing'
        //this.DOMAIN_NAME.FIELDS
      )
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fieldsCost() {
      return this.$store.getters['init/getFields'](screenId)(
        'result_fields_cost'
      )
    },

    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.back.label
      )
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    back,
    getCosts,

    // onRowSelectedBilling(selectedItems) {
    //   this.selectedBilling = selectedItems
    //   getCosts(this, this.selectedBilling[0].billing_id)
    // },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },

  // mounted() {
  //     this.$refs.map.initialize()
  // }
}
</script>

<style lang="scss" scoped>
.detailForm {
  height: 730px;
  overflow-y: auto;
  overflow-x: hidden;
}

#BaseView {
  height: 730px;
}

.edit-button {
  margin-right: 8px;
  margin-bottom: 5px;
  margin-top: 5px;
}
</style>
