import {
  openMsgModal,
  openSubModal,
  displaYyyymmdd,
  formatYYYYMMDD,
  displayYYYYYMMDDHHmmss,
  csvFileName,
  downloadCSV,
} from '@/common/Common.js'
import {
  SCREEN_ID,
  MSG_TYPE,
  DETAIL_SCREEN_KBN,
  COST_STATUS,
} from '@/common/const.js'
import router from '@/router'
import PaymentService from './PaymentService'
import { isNil, isEmpty } from 'lodash'
import _ from 'lodash'

const screenId = SCREEN_ID.PAYMENT_LIST
const detailScreenId = SCREEN_ID.PAYMENT_DETAIL

export function init(obj) {
  // サーバーの処理後再検索
  if (obj.isReSearch == true) {
    reSearch(obj)
  }
}

export function reSearch(obj) {
  var searchCondition = obj.$store.getters['search/getSearchCondition'](
    screenId
  )
  if (searchCondition != null) {
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

/**
 * 場所を検索します.
 * @param {*} obj
 */
export function search(obj) {
  let newSearchCondition = _.cloneDeep(obj.searchCondition)
  let dateFrom = formatYYYYMMDD(obj, newSearchCondition.dateFrom)
  let dateTo = formatYYYYMMDD(obj, newSearchCondition.dateTo)
  newSearchCondition.dateFrom = dateFrom
  newSearchCondition.dateTo = dateTo

  obj.$store.dispatch('init/setServiceLoading')
  PaymentService.search(newSearchCondition)
    .then(
      (response) => {
        obj.tableItems = response.data.resultList

        obj.tableItems.forEach((element) => {
          element.departure_date = displaYyyymmdd(obj, element.departure_date)
          element.arrival_date = displaYyyymmdd(obj, element.arrival_date)
          element.approval_date = displayYYYYYMMDDHHmmss(
            obj,
            element.approval_date
          )
          //請求日、支払日 #2735
          element.billing_date = displaYyyymmdd(obj, element.billing_date)
          element.payment_date = displaYyyymmdd(obj, element.payment_date)
        })

        if (obj.tableItems.length < 1) {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        }
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        // エラーモーダル表示
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

/**
 * 検索項目をクリアします.
 */
export function clear(obj) {
  obj.searchCondition = obj.defaultSearchCondition()
  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

// 「詳細」ボタンクリック
export function goDetail(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        accountingId: obj.selectedItem[0].accounting_id,
        costStatus: obj.selectedItem[0].cost_status,
        registering: obj.selectedItem[0].registering,
        screenKbn: DETAIL_SCREEN_KBN.DETAIL,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}

// 「新規」ボタンクリック
export function goNew() {
  router.push({
    name: detailScreenId,
    params: {
      screenKbn: DETAIL_SCREEN_KBN.NEW,
      beforeScreenRouteInfo: {
        routeInfo: {
          name: screenId,
          params: { isReSearch: true },
        },
      },
    },
  })
}

// 「コピー」ボタンクリック
export function goCopy(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        accountingId: obj.selectedItem[0].accounting_id,
        screenKbn: DETAIL_SCREEN_KBN.COPY,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}

// 「削除」ボタンクリック
export function goRemove(obj) {
  obj.removeMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.delete.label
  )
  openSubModal(obj, 'remove-modal')
}

// 「一括確認」ボタンクリック
export function goAllConfirm(obj) {
  obj.removeMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.all_confirm.label
  )
  openSubModal(obj, 'allconfirm-modal')
}

// 「承認」ボタンクリック
export function goApprove(obj) {
  obj.removeMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.approve.label
  )
  openSubModal(obj, 'approve-modal')
}

// 「変更ロック」ボタンクリック #2781
export function goBatchLock(obj) {
  obj.removeMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.batch_lock.label
  )
  openSubModal(obj, 'batch-lock-modal')
}

// 「ロック解除」ボタンクリック #2781
export function goBatchUnlock(obj) {
  obj.removeMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.batch_unlock.label
  )
  openSubModal(obj, 'batch-unlock-modal')
}

/**
 * 選択した作業データを削除します.
 */
export async function remove(obj) {
  try {
    obj.$bvModal.hide('remove-modal')

    let isError = false
    let removeList = []
    for (let index = 0; index < obj.selectedItem.length; index++) {
      var accountingId = obj.selectedItem[index].accounting_id
      obj.$store.dispatch('init/setServiceLoading')
      await PaymentService.get(accountingId)
        .then(
          (response) => {
            removeList.push(response.data)
          },
          (e) => {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
            isError = true
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
      if (isError) {
        break
      }
    }
    if (isError == false && removeList.length == obj.selectedItem.length) {
      obj.$store.dispatch('init/setServiceLoading')
      await PaymentService.removeList(removeList)
        .then(
          () => {
            // 警告モーダル表示
            openMsgModal(
              obj,
              MSG_TYPE.I,
              obj.$store.getters['init/getMessage'](
                'YZ00MG995I',
                obj.resultControlInfo.delete.label
              )
            )
          },
          (e) => {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
    }
  } catch (err) {
    console.error(err)
  } finally {
    var searchCondition = obj.$store.getters['search/getSearchCondition'](
      screenId
    )
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

// 「CSV出力」ボタンクリック
// 「TXT出力」ボタンクリック
//   -> OBIC請求とOBIC支払
export function download(obj) {
  obj.$bvModal.hide('download-modal')

  console.log('obj.printKbn = ', obj.printKbn)
  if (isNil(obj.printKbn) || isEmpty(obj.printKbn)) {
    //「CSV出力」
    downloadFile(obj)
  } else {
    //「TXT出力」
    downloadObic(obj)
  }
}

// 「CSV出力」ボタンクリック
function downloadFile(obj) {
  //obj.$bvModal.hide('download-modal')
  obj.searchCondition.dateFrom = formatYYYYMMDD(
    obj,
    obj.searchCondition.dateFrom
  )
  obj.searchCondition.dateTo = formatYYYYMMDD(obj, obj.searchCondition.dateTo)

  let newCsvName = csvFileName(obj, screenId)
  obj.searchCondition.filename = newCsvName

  obj.$store.dispatch('init/setServiceLoading')
  PaymentService.download(obj.searchCondition)
    .then(
      (response) => {
        if (response.data == '') {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        } else {
          downloadCSV(obj.searchCondition.filename, response.data)
        }
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, obj.MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

// 「TXT出力」ボタンクリック
//   -> OBIC請求とOBIC支払
function downloadObic(obj) {
  //obj.$bvModal.hide('download-modal')
  obj.searchCondition.dateFrom = formatYYYYMMDD(
    obj,
    obj.searchCondition.dateFrom
  )
  obj.searchCondition.dateTo = formatYYYYMMDD(obj, obj.searchCondition.dateTo)

  obj.searchCondition.salesType = obj.printKbn
  obj.searchCondition.filename = csvFileName(obj, screenId)
  obj.$store.dispatch('init/setServiceLoading')

  PaymentService.makeObicInfo(obj.searchCondition)
    .then(
      (response) => {
        //console.log('response.data', response.data)
        if (response.data.headerFile == null) {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        } else {
          downloadCSV(response.data.headerFile, response.data.headerContent)
          downloadCSV(response.data.lineFile, response.data.lineContent)
        }
      },
      (e) => {
        // エラーモーダル表示
        let msg
        if (e.response === undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, obj.MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

// 「CSV取込」ボタンクリック
export function upload(obj, file) {
  if (file == null || file.name.includes('.csv') == false) {
    // csvファイルを選択してください
    openMsgModal(
      obj,
      obj.MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG936E')
    )
    return
  }

  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', 'csv')

  obj.$store.dispatch('init/setServiceLoading')
  PaymentService.upload(formData)
    .then(
      () => {
        obj.$bvModal.hide('upload-modal')
        openMsgModal(
          obj,
          obj.MSG_TYPE.I,
          obj.$store.getters['init/getMessage']('YZ00MG958I')
        )
        reSearch(obj)
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, obj.MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

/**
 * 一括確認
 * @param {*} obj
 */
export function allConfirm(obj) {
  obj.$bvModal.hide('allconfirm-modal')

  let isError = false
  let confirmList = []
  for (let index = 0; index < obj.selectedItem.length; index++) {
    // 1：請求インターロック解除
    // 3；請求差し戻し
    // console.log(
    //   'obj.selectedItem[index].cost_status',
    //   obj.selectedItem[index].cost_status
    // )
    if (
      obj.selectedItem[index].cost_status == COST_STATUS.INTERLOCK_UNLOCK ||
      obj.selectedItem[index].cost_status == COST_STATUS.REMEND
    ) {
      confirmList.push(obj.selectedItem[index].accounting_id)
    }
  }

  if (confirmList.length == 0) {
    isError = true
    // 警告モーダル表示
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage'](
        'YZ00MG969W',
        obj.resultControlInfo.all_confirm.label
      )
    )
  }

  if (!isError) {
    obj.$store.dispatch('init/setServiceLoading')

    PaymentService.allConfirm(confirmList)
      .then(
        () => {
          // 情報モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.I,
            obj.$store.getters['init/getMessage'](
              'YZ00MG995I',
              obj.resultControlInfo.all_confirm.label
            )
          )
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
        var searchCondition = obj.$store.getters['search/getSearchCondition'](
          screenId
        )
        obj.searchCondition = JSON.parse(searchCondition)
        search(obj)
      })
  }
}

/**
 * 承認
 * @param {*} obj
 */
export function batchApprove(obj) {
  obj.$bvModal.hide('approve-modal')

  let isError = false
  let approveList = []
  for (let index = 0; index < obj.selectedItem.length; index++) {
    //「確定済み」のみ
    console.log(
      'obj.selectedItem[index].cost_status',
      obj.selectedItem[index].cost_status
    )
    if (obj.selectedItem[index].cost_status == COST_STATUS.CONFIRMED) {
      approveList.push(obj.selectedItem[index].accounting_id)
    }
  }

  if (approveList.length == 0) {
    isError = true
    // 警告モーダル表示
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage'](
        'YZ00MG969W',
        obj.resultControlInfo.confirmed.label
      )
    )
  }

  if (!isError) {
    obj.$store.dispatch('init/setServiceLoading')

    PaymentService.batchApprove(approveList)
      .then(
        () => {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.I,
            obj.$store.getters['init/getMessage'](
              'YZ00MG995I',
              obj.resultControlInfo.approve.label
            )
          )
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
        var searchCondition = obj.$store.getters['search/getSearchCondition'](
          screenId
        )
        obj.searchCondition = JSON.parse(searchCondition)
        search(obj)
      })
  }
}

/**
 * 一括変更ロック #2781
 * @param {*} obj
 */
export function batchLock(obj) {
  obj.$bvModal.hide('batch-lock-modal')

  let isError = false
  let approveList = []
  for (let index = 0; index < obj.selectedItem.length; index++) {
    //「変更ロック」のみ
    console.log(
      'obj.selectedItem[index].cost_status',
      obj.selectedItem[index].cost_status
    )
    if (obj.selectedItem[index].cost_status == COST_STATUS.APPROVED) {
      approveList.push(obj.selectedItem[index].accounting_id)
    }
  }

  if (approveList.length == 0) {
    isError = true
    // 警告モーダル表示
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage'](
        'YZ00MG969W',
        obj.resultControlInfo.approve.label
      )
    )
  }

  if (!isError) {
    obj.$store.dispatch('init/setServiceLoading')

    PaymentService.batchLock(approveList)
      .then(
        () => {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.I,
            obj.$store.getters['init/getMessage'](
              'YZ00MG995I',
              obj.resultControlInfo.batch_lock.label
            )
          )
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
        var searchCondition = obj.$store.getters['search/getSearchCondition'](
          screenId
        )
        obj.searchCondition = JSON.parse(searchCondition)
        search(obj)
      })
  }
}

/**
 * 一括ロック解除 #2781
 * @param {*} obj
 */
export function batchUnlock(obj) {
  obj.$bvModal.hide('batch-unlock-modal')

  let isError = false
  let approveList = []
  for (let index = 0; index < obj.selectedItem.length; index++) {
    //「確定済み」のみ
    console.log(
      'obj.selectedItem[index].cost_status',
      obj.selectedItem[index].cost_status
    )
    if (obj.selectedItem[index].cost_status == COST_STATUS.UPDATE_LOCK) {
      approveList.push(obj.selectedItem[index].accounting_id)
    }
  }

  if (approveList.length == 0) {
    isError = true
    // 警告モーダル表示
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage'](
        'YZ00MG969W',
        obj.resultControlInfo.batch_lock.label
      )
    )
  }

  if (!isError) {
    obj.$store.dispatch('init/setServiceLoading')

    PaymentService.batchUnlock(approveList)
      .then(
        () => {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.I,
            obj.$store.getters['init/getMessage'](
              'YZ00MG995I',
              obj.resultControlInfo.batch_unlock.label
            )
          )
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
        var searchCondition = obj.$store.getters['search/getSearchCondition'](
          screenId
        )
        obj.searchCondition = JSON.parse(searchCondition)
        search(obj)
      })
  }
}
