import {
  openMsgModal,
  displaYyyymmdd,
  convertSnakeCace,
} from '@/common/Common.js'
import { MSG_TYPE } from '@/common/const.js'
import BillingService from './BillingService'
import _ from 'lodash'

export function init(obj) {
  var callApi = BillingService.getByAccounting(obj.accountingId)
  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      (response) => {
        obj.formData = response.data
        displayFormData(obj)
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

function displayFormData(obj) {
  let departureDate = displaYyyymmdd(obj, obj.formData.departureDate)
  let arrivalDate = displaYyyymmdd(obj, obj.formData.arrivalDate)
  let billingDate = displaYyyymmdd(obj, obj.formData.billingDate)

  obj.formData.departureDate = departureDate
  obj.formData.arrivalDate = arrivalDate
  obj.formData.billingDate = billingDate

  //請求情報一覧
  let billings = changeSnakeCase(obj.formData.billings, 1)

  billings.forEach((element) => {
    element.departure_date = displaYyyymmdd(obj, element.departure_date)
    element.arrival_date = displaYyyymmdd(obj, element.arrival_date)
    element.billing_date = displaYyyymmdd(obj, element.billing_date)
  })

  obj.formData.billings = billings
  obj.tableItemsBilling = obj.formData.billings

  //金額情報一覧
  var costs = changeSnakeCase(obj.formData.costs, 2)
  obj.formData.costs = costs
  obj.tableItemsCost = obj.formData.costs

  obj.oriData = _.cloneDeep(obj.formData)
}

export function getCosts(obj, billing_id) {
  if (billing_id) {
    // 初期化
    obj.formData.costs = []
    obj.tableItemsCost = []

    BillingService.getCostByBilling(billing_id).then(
      (response) => {
        var costs = changeSnakeCase(response.data, 2)

        costs.forEach((element) => {
          element.billing_date = displaYyyymmdd(obj, element.billing_date)
        })

        obj.formData.costs = costs
        obj.tableItemsCost = obj.formData.costs
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          //msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          return
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
  }
}

function changeSnakeCase(items, kbn) {
  var datas = convertSnakeCace(items)

  if (kbn == 1) {
    datas.sort(function (a, b) {
      return a.billing_id - b.billing_id
    })
  } else if (kbn == 2) {
    datas.sort(function (a, b) {
      return a.cost_id - b.cost_id
    })
  }
  return datas
}
