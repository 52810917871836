<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          style="height: 800px"
          ref="tab-group"
          :inputModel="formData"
          :inputComponents="inputComponents"
        >
          <!--請求金額 -->
          <template v-slot:tab2TableDown>
            <div>
              <!-- 新規登録 -->
              <BaseButton
                class="edit-button"
                variant="success"
                :columnInfo="detailCtrlInfo.billing_add"
                icon="plus-circle"
                @click="billingAddRow(obj)"
              />
              <!-- 編集 -->
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isSelectedBilling"
                :columnInfo="detailCtrlInfo.billing_edit"
                icon="minus-circle"
                @click="billingEditRow(obj)"
              />
              <!-- 運賃計算-->
              <BaseButton
                class="edit-button"
                variant="success"
                :columnInfo="detailCtrlInfo.billing_calc"
                icon="minus-circle"
                @click="billingCalcRow(obj)"
              />
              <!-- 削除-->
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isSelectedBilling"
                :columnInfo="detailCtrlInfo.billing_delete"
                icon="minus-circle"
                @click="billingRemoveRow(obj)"
              />
            </div>
            <div class="cost-tab-pane">
              <!-- テーブル -->
              <BaseTable
                select-mode="range"
                ref="table"
                :fields="fieldsBilling"
                :items="tableItemsBilling"
                selectMode="single"
                @row-selected="onRowSelectedBilling"
                @row-clicked="onRowClickedBilling"
              />
            </div>
          </template>
          <!--支払金額 -->
          <template v-slot:tab3TableDown>
            <div>
              <!-- 新規登録 -->
              <BaseButton
                class="edit-button"
                variant="success"
                :columnInfo="detailCtrlInfo.payment_add"
                icon="plus-circle"
                @click="paymentAddRow(obj)"
              />
              <!-- 編集 -->
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isSelectedPayment"
                :columnInfo="detailCtrlInfo.payment_edit"
                icon="minus-circle"
                @click="paymentEditRow(obj)"
              />
              <!-- 運賃計算-->
              <BaseButton
                class="edit-button"
                variant="success"
                :columnInfo="detailCtrlInfo.payment_calc"
                icon="minus-circle"
                @click="paymentCalcRow(obj)"
              />
              <!-- 削除-->
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isSelectedPayment"
                :columnInfo="detailCtrlInfo.payment_delete"
                icon="minus-circle"
                @click="paymentRemoveRow(obj)"
              />
            </div>
            <div class="cost-tab-pane">
              <!-- テーブル -->
              <BaseTable
                select-mode="range"
                ref="table"
                :fields="fieldsPayment"
                :items="tableItemsPayment"
                selectMode="single"
                @row-selected="onRowSelectedPayment"
                @row-clicked="onRowClickedPayment"
              />
            </div>
          </template>
        </BaseTabGroup>
      </template>
    </DetailPageLayout>

    <!-- 情報モーダル -->
    <InfoModal
      id="detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="detail-info2-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
    <!-- 確定モーダル -->
    <ConfirmModal
      id="confirm-modal"
      :columnInfos="messageInfo"
      :message="confirmMessage"
      @confirm="confirm(obj)"
    />
    <!-- 承認モーダル -->
    <ConfirmModal
      id="approve-modal"
      :columnInfos="messageInfo"
      :message="approveMessage"
      @confirm="approve(obj)"
    />
    <!-- 承認モーダル -->
    <ConfirmModal
      id="lock-modal"
      :columnInfos="messageInfo"
      :message="lockMessage"
      @confirm="lock(obj)"
    />
    <!-- 差し戻しモーダル -->
    <ConfirmModal
      id="remand-modal"
      :columnInfos="messageInfo"
      :message="remandMessage"
      @confirm="remand(obj)"
    />

    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="department-modal-three"
      type="entry"
      :selectedSearchCondition="departmentModalSearchCondition"
      displayModals="three"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 商品マスタモーダル -->
    <ProductModal
      id="product-modal"
      type="entry"
      @after-close-set="afterCloseSetProduct"
      @after-close-unset="afterCloseUnsetProduct"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 場所モーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 輸送手段マスタモーダル -->
    <VehicleModal
      id="vehicle-modal-edit"
      type="entry"
      selectMode="single"
      displayModals="two"
      @after-close-set="afterCloseSetVehicle"
      @after-close-unset="afterCloseUnsetVehicle"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 実車番マスタモーダル -->
    <RealVehicleModal
      id="real-vehicle-modal-four"
      type="entry"
      displayModals="four"
      :selectedSearchCondition="realVehicleModalSearchCondition"
      @after-close-set="afterCloseSetRealVehicle"
      @after-close-unset="afterCloseUnsetRealVehicle"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 金額設定編集モーダル -->
    <AmountEditModal
      id="amount-edit-modal"
      :getDatas.sync="editModalData"
      @after-close-set="afterCloseSetAmountEdit"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
      @set-info-msg="setInfoMsg"
    />
  </div>
</template>

<script>
import PaymentInfo from './PaymentInfo'
import { getListOptions, getMasterName, openSubModal } from '@/common/Common.js'
import {
  COST_STATUS,
  DEPARTMENT_KIND,
  DETAIL_SCREEN_KBN,
  INTEGER_MAX_MIN,
  MANUAL_INPUT,
  REAL_VEHICLE_KIND_SEARCH,
  SCREEN_ID,
  SLOT_NAME,
  VISIBILITY,
} from '@/common/const.js'
import { back, getBreadcrumbItems } from '@/common/screen-common.js'
import {
  afterCloseSetAmountEditInfo,
  approve,
  billingAddRow,
  billingCalcRow,
  billingEditRow,
  billingRemoveRow,
  clear,
  confirm,
  goApprove,
  goConfirm,
  goLock,
  goRegister,
  goRemand,
  init,
  lock,
  paymentAddRow,
  paymentCalcRow,
  paymentEditRow,
  paymentRemoveRow,
  register,
  remand,
  setReadOnlyDetailInfo,
} from '@/accounting/payment/PaymentDetail.js'

const screenId = SCREEN_ID.PAYMENT_DETAIL

export default {
  name: 'paymentDetail',

  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    ProductModal: () => import('@/master/product/ProductModal.vue'),
    VehicleModal: () => import('@/master/vehicle/VehicleModal.vue'),
    RealVehicleModal: () => import('@/master/realVehicle/RealVehicleModal.vue'),
    AmountEditModal: () => import('@/accounting/payment/AmountEditModal.vue'),
  },
  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    accountingId: {
      type: Number,
      default: -1,
    },
    costStatus: {
      type: String,
      default: '',
    },
    registering: {
      type: String,
      default: '',
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      selectLonLat: null,
      formData: new PaymentInfo(),
      tableItemsBilling: [],
      tableItemsPayment: [],
      // isSelectedXXX, onRowSelectedXXX
      selectedBilling: [],
      selectedPayment: [],
      obj: this,
      registerMessage: null,
      confirmMessage: null,
      approveMessage: null,
      remandMessage: null,
      lockMessage: null,
      oriData: new PaymentInfo(),
      cityModalSearchCondition: {},
      // amount modal patameter
      editModalData: {},
      // 削除用情報格納
      oriTableItemsBilling: [],
      oriTableItemsPayment: [],
      // clear用情報格納
      originalTableItemsBilling: [],
      originalTableItemsPayment: [],

      detailRowIndexBilling: -1,
      detailRowIndexPaymenet: -1,
      // NEW, DETAIL
      detailScreenKbn: -1,
      // tab2 = 2, tab3 = 3
      tabKbn: -1,

      //DEPARTMENT_KIND_OWNER: DEPARTMENT_KIND.OWNER,
      //REAL_VEHICLE_KIND: REAL_VEHICLE_KIND.TRTC,
      departmentModalKbn: -1,
      realVehicleModalKbn: -1,
      placeModalKbn: -1,
      realVehicleModalSearchCondition: {},
      departmentModalSearchCondition: {},
      btnMsgLabel: null,
      // 運賃手入力区分(請求、支払)
      inputFlgPayment: MANUAL_INPUT.AUTO,
      inputFlgBilling: MANUAL_INPUT.AUTO,
    }
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      let info = this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )

      if (
        this.costStatus === COST_STATUS.CONFIRMED ||
        this.costStatus === COST_STATUS.APPROVED ||
        this.costStatus === COST_STATUS.UPDATE_LOCK
      ) {
        info.billing_add.visibility = VISIBILITY.DISABLE
        info.billing_edit.visibility = VISIBILITY.DISABLE
        info.billing_calc.visibility = VISIBILITY.DISABLE
        info.billing_delete.visibility = VISIBILITY.DISABLE

        info.payment_add.visibility = VISIBILITY.DISABLE
        info.payment_edit.visibility = VISIBILITY.DISABLE
        info.payment_calc.visibility = VISIBILITY.DISABLE
        info.payment_delete.visibility = VISIBILITY.DISABLE
      }

      return info
    },
    detailInfo() {
      let info = this.$store.getters['init/getScreens'](screenId)('detail_info')
      if (
        this.costStatus === COST_STATUS.CONFIRMED ||
        this.costStatus === COST_STATUS.APPROVED
      ) {
        info.remand_comment_code.visibility = VISIBILITY.VISIBLE
        info.remand_comment.visibility = VISIBILITY.VISIBLE
      } else {
        info.remand_comment_code.visibility = VISIBILITY.DISABLE
        info.remand_comment.visibility = VISIBILITY.DISABLE
      }

      if (
        this.costStatus === COST_STATUS.CEREATED ||
        this.costStatus === COST_STATUS.INTERLOCK_UNLOCK ||
        this.costStatus === COST_STATUS.REMEND
      ) {
        info.approval_request_comment.visibility = VISIBILITY.VISIBLE
      } else {
        info.approval_request_comment.visibility = VISIBILITY.DISABLE
      }

      if (
        this.costStatus === COST_STATUS.CONFIRMED ||
        this.costStatus === COST_STATUS.APPROVED ||
        this.costStatus === COST_STATUS.UPDATE_LOCK
      ) {
        info = setReadOnlyDetailInfo(info)
      }
      return info
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(this.beforeScreenRouteInfo, screenId)
    },

    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fieldsBilling() {
      return this.$store.getters['init/getFields'](screenId)(
        //'result_fields_billings'
        this.DOMAIN_NAME.FIELDS
      )
    },

    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fieldsPayment() {
      return this.$store.getters['init/getFields'](screenId)(
        'result_fields_payments'
      )
    },

    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            disabled: !this.enableRegist,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 確定ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.confirm,
            disabled: !this.enableConfirmed,
            onClickCallback: () => {
              goConfirm(this)
            },
          },
          // 承認ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.approve,
            disabled: !this.enablepproved,
            onClickCallback: () => {
              goApprove(this)
            },
          },
          // 差し戻しボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.remand,
            disabled: !this.enableRemend,
            onClickCallback: () => {
              goRemand(this)
            },
          },
          // 変更ロックボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.lock,
            disabled: !this.enableLock,
            onClickCallback: () => {
              goLock(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
      ]
    },
    inputComponents() {
      return [
        {
          // 基本情報タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 経費ID
              type: 'text',
              id: 'accountingId',
              columnInfo: this.detailInfo.accounting_id,
            },
            {
              // データ作成区分
              type: 'text',
              id: 'dataRegistrationType',
              columnInfo: this.detailInfo.data_registration_type,
            },
            {
              // データ作成区分
              type: 'text',
              id: 'dataRegistrationTypeName',
              columnInfo: this.detailInfo.data_registration_type_name,
            },
            {
              // 請求支払ステータス
              type: 'text',
              id: 'costStatus',
              columnInfo: this.detailInfo.cost_status,
            },
            {
              // 請求支払ステータス
              type: 'text',
              id: 'costStatusName',
              columnInfo: this.detailInfo.cost_status_name,
            },
            {
              // 入力状態
              type: 'select',
              id: 'registering',
              columnInfo: this.detailInfo.registering,
              options: getListOptions(this.MASTER_CODE.REGISTERING, false),
              onChangeCallback: () => {},
            },
            {
              // 運賃手入力区分
              type: 'text',
              id: 'manualInput',
              columnInfo: this.detailInfo.manual_input,
            },
            {
              // 運賃手入力区分
              type: 'text',
              id: 'manualInputName',
              columnInfo: this.detailInfo.manual_input_name,
            },
            {
              // OBIC連携済みフラグ
              type: 'text',
              id: 'sendFlg',
              columnInfo: this.detailInfo.send_flg,
            },
            {
              // OBIC連携済みフラグ
              type: 'text',
              id: 'sendFlgName',
              columnInfo: this.detailInfo.send_flg_name,
            },
            {
              // 荷主
              type: 'referenceCodeName',
              codeId: 'ownerCode',
              nameId: 'ownerName',
              codeColumnInfo: this.detailInfo.owner_code,
              nameColumnInfo: this.detailInfo.owner_name,
              btnColumnInfo: this.detailInfo.sub_modal_owner,
              codeRules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
              ],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.departmentModalKbn = 1
                // 荷主
                this.departmentModalSearchCondition = {
                  departmentKind: DEPARTMENT_KIND.OWNER,
                }
                this.openSubModal(this, 'department-modal-three')
              },
            },
            {
              // 承認依頼コメント
              type: 'text',
              id: 'approvalRequestComment',
              columnInfo: this.detailInfo.approval_request_comment,
            },
            {
              // 差し戻しコメント（コード）
              type: 'select',
              id: 'remandCommentCode',
              columnInfo: this.detailInfo.remand_comment_code,
              options: getListOptions(this.MASTER_CODE.SEND_BACK_COMMENT),
              onChangeCallback: () => {
                this.formData.remandComment = getMasterName(
                  this.MASTER_CODE.SEND_BACK_COMMENT,
                  this.formData.remandCommentCode
                )
              },
            },
            {
              // 差し戻しコメント
              type: 'text',
              id: 'remandComment',
              columnInfo: this.detailInfo.remand_comment,
            },
            {
              // 商品
              type: 'referenceCode',
              codeId: 'productName',
              codeColumnInfo: this.detailInfo.product_name,
              btnColumnInfo: this.detailInfo.sub_modal_product,
              onClickCallback: () => {
                this.openSubModal(this, 'product-modal')
              },
            },
            {
              //タリフ判別商品コード
              type: 'text',
              id: 'tariffProductCode',
              columnInfo: this.detailInfo.tariff_product_code,
              maxLength: 50,
            },
            {
              // 数量
              type: 'text',
              id: 'quantity',
              columnInfo: this.detailInfo.quantity,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 質量
              type: 'text',
              id: 'weight',
              columnInfo: this.detailInfo.weight,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.FLOAT,
              ],
            },
            {
              // 容量
              type: 'text',
              id: 'volume',
              columnInfo: this.detailInfo.volume,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.FLOAT,
              ],
            },
            {
              // 出発場所
              type: 'referenceCode',
              codeId: 'departurePlaceName',
              codeColumnInfo: this.detailInfo.departure_place_name,
              btnColumnInfo: this.detailInfo.sub_modal_departure_place,
              onClickCallback: () => {
                //...ボタン
                this.placeModalKbn = 1
                this.openSubModal(this, 'place-modal')
              },
            },
            {
              // 出発日付
              type: 'date',
              id: 'departureDate',
              columnInfo: this.detailInfo.departure_date,
              //rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 到着場所
              type: 'referenceCode',
              codeId: 'arrivalPlaceName',
              codeColumnInfo: this.detailInfo.arrival_place_name,
              btnColumnInfo: this.detailInfo.sub_modal_arrival_place,
              onClickCallback: () => {
                this.placeModalKbn = 2
                this.openSubModal(this, 'place-modal')
              },
            },
            {
              // 到着日付
              type: 'date',
              id: 'arrivalDate',
              columnInfo: this.detailInfo.arrival_date,
              //rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 運送会社
              type: 'referenceCodeName',
              codeId: 'carrierCode',
              nameId: 'carrierName',
              codeColumnInfo: this.detailInfo.carrier_code,
              nameColumnInfo: this.detailInfo.carrier_name,
              btnColumnInfo: this.detailInfo.sub_modal_carrier,
              //codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              //nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.departmentModalKbn = 2
                // 運送会社
                this.departmentModalSearchCondition = {
                  departmentKind: DEPARTMENT_KIND.CARRIER,
                }
                this.openSubModal(this, 'department-modal-three')
              },
            },
            {
              // 輸送手段
              type: 'referenceCodeName',
              codeId: 'vehicleCode',
              nameId: 'vehicleName',
              codeColumnInfo: this.detailInfo.vehicle_code,
              nameColumnInfo: this.detailInfo.vehicle_name,
              btnColumnInfo: this.detailInfo.sub_modal_vehicle,
              codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
              onClickCallback: () => {
                this.openSubModal(this, 'vehicle-modal-edit')
              },
            },
            {
              // トラック/トレーラー区分
              type: 'select',
              id: 'realVehicleKind',
              columnInfo: this.detailInfo.real_vehicle_kind,
              options: getListOptions(this.MASTER_CODE.REAL_VEHICLE_KIND),
              onChangeCallback: () => {},
            },
            {
              // トラック/トラクタ (TR)
              type: 'referenceCodeName',
              codeId: 'realVehicleId',
              nameId: 'realVehicleNumberplate',
              codeColumnInfo: this.detailInfo.real_vehicle_id,
              nameColumnInfo: this.detailInfo.real_vehicle_numberplate,
              btnColumnInfo: this.detailInfo.sub_modal_real_vehicle,
              onClickCallback: () => {
                this.realVehicleModalKbn = 1
                this.realVehicleModalSearchCondition = {}
                this.realVehicleModalSearchCondition.realVehicleKind =
                  REAL_VEHICLE_KIND_SEARCH.TRTC
                this.openSubModal(this, 'real-vehicle-modal-four')
              },
            },
            {
              // トレーラー (TL)
              type: 'referenceCodeName',
              codeId: 'realTrailerId',
              nameId: 'realTrailerNumberplate',
              codeColumnInfo: this.detailInfo.real_trailer_id,
              nameColumnInfo: this.detailInfo.real_trailer_numberplate,
              btnColumnInfo: this.detailInfo.sub_modal_real_trailer,
              onClickCallback: () => {
                this.realVehicleModalKbn = 2
                this.realVehicleModalSearchCondition = {}
                this.realVehicleModalSearchCondition.realVehicleKind =
                  REAL_VEHICLE_KIND_SEARCH.TL
                this.openSubModal(this, 'real-vehicle-modal-four')
              },
            },
            {
              // 作業実績日付
              type: 'date',
              id: 'workActDate',
              columnInfo: this.detailInfo.work_act_date,
            },
            {
              // 実走行距離
              type: 'text',
              id: 'actualDistance',
              columnInfo: this.detailInfo.actual_distance,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 承認依頼日時
              type: 'text',
              id: 'approvalRequestDatetimeDisplay',
              columnInfo: this.detailInfo.approval_request_datetime_display,
            },
            {
              // 承認依頼者
              type: 'text',
              id: 'approvalRequestUserName',
              columnInfo: this.detailInfo.approval_request_user_name,
            },
            {
              // 承認日
              // type: 'date',
              // id: 'approvalDate',
              // columnInfo: this.detailInfo.approval_date,
              type: 'text',
              id: 'approvalDate',
              columnInfo: this.detailInfo.approval_date,
            },
            // {
            //   // 承認者
            //   type: 'text',
            //   id: 'approvalUser',
            //   columnInfo: this.detailInfo.approval_user,
            // },
            {
              // 承認者名
              type: 'text',
              id: 'approvalUserName',
              columnInfo: this.detailInfo.approval_user_name,
            },
            {
              // 通行区分(往復区分)
              type: 'select',
              id: 'onewayOrRound',
              columnInfo: this.detailInfo.oneway_or_round,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(this.MASTER_CODE.GO_OR_BACK),
              onChangeCallback: () => {},
            },
            {
              // 2台積区分
              type: 'select',
              id: 'isMultipleLoading',
              columnInfo: this.detailInfo.is_multiple_loading,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(this.MASTER_CODE.IS_MULTIPLE_LOADING),
              onChangeCallback: () => {},
            },
            {
              // 仕向国
              type: 'text',
              id: 'destinationCountry',
              columnInfo: this.detailInfo.destination_country,
            },
            {
              // 実績登録作業区分
              type: 'select',
              id: 'recordWorkKind',
              columnInfo: this.detailInfo.record_work_kind,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(this.MASTER_CODE.RECORD_WORK_KIND),
              onChangeCallback: () => {},
            },
            {
              //予備１ (機種)
              type: 'text',
              id: 'reserve01',
              columnInfo: this.detailInfo.reserve01,
              maxLength: 50,
            },
            {
              //予備２ (機番)
              type: 'text',
              id: 'reserve02',
              columnInfo: this.detailInfo.reserve02,
              maxLength: 50,
            },
            {
              //予備３ (受付番号)
              type: 'text',
              id: 'reserve03',
              columnInfo: this.detailInfo.reserve03,
              maxLength: 50,
            },
            {
              //予備４ (発注距離[km])
              type: 'text',
              id: 'reserve04',
              columnInfo: this.detailInfo.reserve04,
              maxLength: 50,
            },
            {
              //予備５ (実走行距離[km])
              type: 'text',
              id: 'reserve05',
              columnInfo: this.detailInfo.reserve05,
              maxLength: 50,
            },
            {
              //予備６ (タリフ判別コード)
              type: 'text',
              id: 'reserve06',
              columnInfo: this.detailInfo.reserve06,
              maxLength: 50,
            },
            {
              //予備７ (長さ[cm])
              type: 'text',
              id: 'reserve07',
              columnInfo: this.detailInfo.reserve07,
              maxLength: 50,
            },
            {
              //予備８ (幅[cm])
              type: 'text',
              id: 'reserve08',
              columnInfo: this.detailInfo.reserve08,
              maxLength: 50,
            },
            {
              //予備９ (高さ[cm])
              type: 'text',
              id: 'reserve09',
              columnInfo: this.detailInfo.reserve09,
              maxLength: 50,
            },
            {
              //予備１０ (配車結果番号)
              type: 'text',
              id: 'reserve10',
              columnInfo: this.detailInfo.reserve10,
              maxLength: 50,
            },
          ],
        },
        {
          // 請求金額タブ
          label: this.detailInfo.tab2.label,
          slot: SLOT_NAME.TAB2_TABLE_DOWN,
          inputComponents: [
            {
              // ID
              type: 'text',
              id: 'billingId',
              columnInfo: this.detailInfo.billing_id,
            },
            {
              // ブロックコード
              type: 'text',
              id: 'billingBlockCode',
              columnInfo: this.detailInfo.billing_block_code,
            },
            {
              // 請求先部署名
              type: 'referenceCodeName',
              codeId: 'billingDepartmentCode',
              nameId: 'billingDepartmentName',
              codeColumnInfo: this.detailInfo.billing_department_code,
              nameColumnInfo: this.detailInfo.billing_department_name,
              btnColumnInfo: this.detailInfo.sub_modal_department_billing,
              //codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              //nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.departmentModalKbn = 3
                this.departmentModalSearchCondition = {
                  departmentKind: null,
                }
                this.openSubModal(this, 'department-modal-three')
              },
            },
            {
              // 請求日
              type: 'date',
              id: 'billingDate',
              columnInfo: this.detailInfo.billing_date,
            },
            {
              //請求部門
              type: 'text',
              id: 'invoiceDepartmentCode',
              columnInfo: this.detailInfo.invoice_department_code,
              maxLength: 50,
            },
            {
              //売上部門
              type: 'text',
              id: 'salesDepartmentCode',
              columnInfo: this.detailInfo.sales_department_code,
              maxLength: 50,
            },
            {
              //予備１
              type: 'text',
              id: 'billingReserve01',
              columnInfo: this.detailInfo.billing_reserve01,
              maxLength: 50,
            },
            {
              //予備２
              type: 'text',
              id: 'billingReserve02',
              columnInfo: this.detailInfo.billing_reserve02,
              maxLength: 50,
            },
            {
              //予備３
              type: 'text',
              id: 'billingReserve03',
              columnInfo: this.detailInfo.billing_reserve03,
              maxLength: 50,
            },
            {
              //予備４
              type: 'text',
              id: 'billingReserve04',
              columnInfo: this.detailInfo.billing_reserve04,
              maxLength: 50,
            },
            {
              //予備５
              type: 'text',
              id: 'billingReserve05',
              columnInfo: this.detailInfo.billing_reserve05,
              maxLength: 50,
            },
            {
              //予備６
              type: 'text',
              id: 'billingReserve06',
              columnInfo: this.detailInfo.billing_reserve06,
              maxLength: 50,
            },
            {
              //予備７
              type: 'text',
              id: 'billingReserve07',
              columnInfo: this.detailInfo.billing_reserve07,
              maxLength: 50,
            },
            {
              //予備８
              type: 'text',
              id: 'billingReserve08',
              columnInfo: this.detailInfo.billing_reserve08,
              maxLength: 50,
            },
            {
              //予備９
              type: 'text',
              id: 'billingReserve09',
              columnInfo: this.detailInfo.billing_reserve09,
              maxLength: 50,
            },
            {
              //予備１０
              type: 'text',
              id: 'billingReserve10',
              columnInfo: this.detailInfo.billing_reserve10,
              maxLength: 50,
            },
            {
              //入力担当者ID
              type: 'text',
              id: 'inputStaffId',
              columnInfo: this.detailInfo.input_staff_id,
              maxLength: 50,
            },
            {
              //営業担当者ID
              type: 'text',
              id: 'salesStaffId',
              columnInfo: this.detailInfo.sales_staff_id,
              maxLength: 50,
            },
            {
              // 送信済みフラグ
              type: 'select',
              id: 'billingSendFlg',
              columnInfo: this.detailInfo.billing_send_flg,
              options: getListOptions(this.MASTER_CODE.SEND_FLG),
              onChangeCallback: () => {},
            },
          ],
        },
        {
          // 支払金額タブ
          label: this.detailInfo.tab3.label,
          slot: SLOT_NAME.TAB3_TABLE_DOWN,
          inputComponents: [
            {
              // ID
              type: 'text',
              id: 'paymentId',
              columnInfo: this.detailInfo.payment_id,
            },
            {
              // ブロックコード
              type: 'text',
              id: 'paymentBlockCode',
              columnInfo: this.detailInfo.payment_block_code,
            },
            {
              // 運送会社
              type: 'referenceCodeName',
              codeId: 'paymentCarrierCode',
              nameId: 'paymentCarrierName',
              codeColumnInfo: this.detailInfo.payment_carrier_code,
              nameColumnInfo: this.detailInfo.payment_carrier_name,
              btnColumnInfo: this.detailInfo.sub_modal_carrier_payment,
              //codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              //nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.departmentModalKbn = 4
                this.departmentModalSearchCondition = {
                  departmentKind: DEPARTMENT_KIND.CARRIER,
                }
                this.openSubModal(this, 'department-modal-three')
              },
            },
            {
              // 支払日
              type: 'date',
              id: 'paymentDate',
              columnInfo: this.detailInfo.payment_date,
            },
            {
              //予備１
              type: 'text',
              id: 'paymentReserve01',
              columnInfo: this.detailInfo.payment_reserve01,
              maxLength: 50,
            },
            {
              //予備２
              type: 'text',
              id: 'paymentReserve02',
              columnInfo: this.detailInfo.payment_reserve02,
              maxLength: 50,
            },
            {
              //予備３
              type: 'text',
              id: 'paymentReserve03',
              columnInfo: this.detailInfo.payment_reserve03,
              maxLength: 50,
            },
            {
              //予備４
              type: 'text',
              id: 'paymentReserve04',
              columnInfo: this.detailInfo.payment_reserve04,
              maxLength: 50,
            },
            {
              //予備５
              type: 'text',
              id: 'paymentReserve05',
              columnInfo: this.detailInfo.payment_reserve05,
              maxLength: 50,
            },
            {
              //予備６
              type: 'text',
              id: 'paymentReserve06',
              columnInfo: this.detailInfo.payment_reserve06,
              maxLength: 50,
            },
            {
              //予備７
              type: 'text',
              id: 'paymentReserve07',
              columnInfo: this.detailInfo.payment_reserve07,
              maxLength: 50,
            },
            {
              //予備８
              type: 'text',
              id: 'paymentReserve08',
              columnInfo: this.detailInfo.payment_reserve08,
              maxLength: 50,
            },
            {
              //予備９
              type: 'text',
              id: 'paymentReserve09',
              columnInfo: this.detailInfo.payment_reserve09,
              maxLength: 50,
            },
            {
              //予備１０
              type: 'text',
              id: 'paymentReserve10',
              columnInfo: this.detailInfo.payment_reserve10,
              maxLength: 50,
            },
            {
              // 送信済みフラグ
              type: 'select',
              id: 'paymentSendFlg',
              columnInfo: this.detailInfo.payment_send_flg,
              options: getListOptions(this.MASTER_CODE.SEND_FLG),
              onChangeCallback: () => {},
            },
          ],
        },
      ]
    },

    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage: function () {
      let msgLbl = this.btnMsgLabel
      if (msgLbl == null) {
        msgLbl = this.detailCtrlInfo.register.label
      }
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        //this.detailCtrlInfo.register.label
        msgLbl
      )
    },

    isSelectedPayment() {
      return this.selectedPayment.length > 0
    },
    isSelectedBilling() {
      return this.selectedBilling.length > 0
    },

    /**
     * 登録可能か判定
     */
    enableRegist() {
      //請求支払ステータス
      if (
        this.screenKbn == DETAIL_SCREEN_KBN.COPY ||
        this.screenKbn == DETAIL_SCREEN_KBN.NEW
      ) {
        return true
      }

      if (
        this.screenKbn == DETAIL_SCREEN_KBN.DETAIL &&
        (this.formData.costStatus == COST_STATUS.INTERLOCK_UNLOCK ||
          this.formData.costStatus == COST_STATUS.CEREATED ||
          this.formData.costStatus == COST_STATUS.REMEND)
      ) {
        return true
      }
      return false
    },

    /**
     * 確定可能か判定
     */
    enableConfirmed() {
      //請求支払ステータス
      // '1':インターロック解除、
      // '3':差戻
      let info = this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )

      if (
        info.confirm.visibility == VISIBILITY.VISIBLE && //承認ボタン使用可
        (this.formData.costStatus == COST_STATUS.INTERLOCK_UNLOCK ||
          this.formData.costStatus == COST_STATUS.REMEND) &&
        this.screenKbn == DETAIL_SCREEN_KBN.DETAIL
      ) {
        return true //活性化
      }
      return false
    },
    /**
     * 承認可能か判定
     */
    enablepproved() {
      let isApprove = false
      //請求支払ステータス
      // '2':確定済み CONFIRMED
      let info = this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )

      if (
        this.screenKbn == DETAIL_SCREEN_KBN.DETAIL && //編集モード
        info.approve.visibility == VISIBILITY.VISIBLE && //承認ボタン使用可
        this.formData.costStatus == COST_STATUS.CONFIRMED
      ) {
        isApprove = true //活性化
      }
      return isApprove
    },
    /**
     * 差し戻し・承認可能か判定
     */
    enableRemend() {
      let isApprove = false
      //請求支払ステータス
      // '2':確定済み CONFIRMED
      let info = this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )

      if (
        this.screenKbn == DETAIL_SCREEN_KBN.DETAIL && //編集モード
        info.remand.visibility == VISIBILITY.VISIBLE && //承認ボタン使用可
        (this.formData.costStatus == COST_STATUS.CONFIRMED ||
          this.formData.costStatus == COST_STATUS.APPROVED)
      ) {
        isApprove = true //活性化
      }
      return isApprove
    },
    /**
     * 更新ロック可能か判定
     */
    enableLock() {
      let isLock = false
      //ユーザーのロール取得(承認権限：APPROVE、ADMIN)
      //請求支払ステータス
      // '4':承認済み APROVED
      let info = this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )

      if (
        this.screenKbn == DETAIL_SCREEN_KBN.DETAIL && //編集モード
        info.lock.visibility == VISIBILITY.VISIBLE && //変更ロックボタン使用可
        this.formData.costStatus == COST_STATUS.APPROVED
      ) {
        isLock = true //活性化
      }
      return isLock
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    register,
    goConfirm,
    confirm,
    goApprove,
    approve,
    goRemand,
    remand,
    goLock,
    lock,
    back,
    billingAddRow,
    billingEditRow,
    billingCalcRow,
    billingRemoveRow,
    paymentAddRow,
    paymentEditRow,
    paymentCalcRow,
    paymentRemoveRow,
    afterCloseSetAmountEditInfo,

    onRowSelectedBilling(selectedItems) {
      this.selectedBilling = selectedItems
    },
    onRowClickedBilling(item, index) {
      this.detailRowIndexBilling = index
    },

    onRowSelectedPayment(selectedItems) {
      this.selectedPayment = selectedItems
    },
    onRowClickedPayment(item, index) {
      this.detailRowIndexPayment = index
    },

    afterCloseSetAmountEdit(info) {
      afterCloseSetAmountEditInfo(this, info)
    },

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      if (this.departmentModalKbn == 1) {
        // 基本情報タブ 荷主
        this.formData.ownerId = id
        this.formData.ownerCode = code
        this.formData.ownerName = name
      } else if (this.departmentModalKbn == 2) {
        // 基本情報タブ 運送会社
        this.formData.carrierId = id
        this.formData.carrierCode = code
        this.formData.carrierName = name
      } else if (this.departmentModalKbn == 3) {
        // 請求金額タブ 請求先部署名
        this.formData.billingDepartmentId = id
        this.formData.billingDepartmentCode = code
        this.formData.billingDepartmentName = name

        // ※参照情報反映が画面に反映されないバグ対応⇒配列の更新反映
        this.tableItemsBilling.sort(function (a, b) {
          return a.cost_id - b.cost_id
        })
      } else if (this.departmentModalKbn == 4) {
        // 支払金額タブ 運送会社
        this.formData.paymentCarrierId = id
        this.formData.paymentCarrierCode = code
        this.formData.paymentCarrierName = name

        // ※参照情報反映が画面に反映されないバグ対応⇒配列の更新反映
        this.tableItemsPayment.sort(function (a, b) {
          return a.cost_id - b.cost_id
        })
      }
    },

    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      if (this.departmentModalKbn == 1) {
        this.formData.ownerId = null
        this.formData.ownerCode = null
        this.formData.ownerName = null
      } else if (this.departmentModalKbn == 2) {
        this.formData.carrierId = null
        this.formData.carrierCode = null
        this.formData.carrierName = null
      } else if (this.departmentModalKbn == 3) {
        this.formData.billingDepartmentId = null
        this.formData.billingDepartmentCode = null
        this.formData.billingDepartmentName = null
        // ※参照情報反映が画面に反映されないバグ対応⇒配列の更新反映
        this.tableItemsBilling.sort(function (a, b) {
          return a.cost_id - b.cost_id
        })
      } else if (this.departmentModalKbn == 4) {
        this.formData.paymentCarrierId = null
        this.formData.paymentCarrierCode = null
        this.formData.paymentCarrierName = null
        // ※参照情報反映が画面に反映されないバグ対応⇒配列の更新反映
        this.tableItemsPayment.sort(function (a, b) {
          return a.cost_id - b.cost_id
        })
      }
    },

    /**
     * 商品マスタから取得データ設定
     */
    afterCloseSetProduct(selectedItem) {
      this.formData.productId = selectedItem[0].product_id
      this.formData.tariffProductCode = selectedItem[0].tariff_product_code
      this.formData.productName = selectedItem[0].product_name
    },

    /**
     * 商品情報解除
     */
    afterCloseUnsetProduct() {
      this.formData.productId = null
      this.formData.tariffProductCode = null
      this.formData.productName = null
    },

    /**
     * 輸送手段マスタから取得データ設定
     */
    afterCloseSetVehicle(selectedItem) {
      this.formData.vehicleId = selectedItem.vehicle_id
      this.formData.vehicleCode = selectedItem.vehicle_code
      this.formData.vehicleName = selectedItem.vehicle_name
    },
    /**
     * 輸送手段情報解除
     */
    afterCloseUnsetVehicle() {
      this.formData.vehicleId = null
      this.formData.vehicleCode = null
      this.formData.vehicleName = null
    },

    /**
     * 実車番マスタから取得データ設定
     */
    afterCloseSetRealVehicle(selectedItem) {
      if (this.realVehicleModalKbn == 1) {
        //トラック/トラクタ(TR)
        this.formData.realVehicleId = selectedItem.real_vehicle_id
        this.formData.realVehicleNumberplate = selectedItem.numberplate
      } else if (this.realVehicleModalKbn == 2) {
        //トレーラー (TL)
        this.formData.realTrailerId = selectedItem.real_vehicle_id
        this.formData.realTrailerNumberplate = selectedItem.numberplate
      }
    },
    afterCloseUnsetRealVehicle() {
      if (this.realVehicleModalKbn == 1) {
        //トラック/トラクタ(TR)
        this.formData.realVehicleId = null
        this.formData.realVehicleNumberplate = null
      } else if (this.realVehicleModalKbn == 2) {
        //トレーラー (TL)
        this.formData.realTrailerId = null
        this.formData.realTrailerNumberplate = null
      }
    },

    /**
     * 場所マスタから取得データ設定
     */
    afterCloseSetPlace(selectedItem) {
      if (this.placeModalKbn == 1) {
        this.formData.departurePlaceId = selectedItem.place_id
        this.formData.departurePlaceCode = selectedItem.place_code
        this.formData.departurePlaceName = selectedItem.place_name
        this.formData.departurePlaceAddress =
          selectedItem.address1 + '' + selectedItem.address2
      } else {
        this.formData.arrivalPlaceId = selectedItem.place_id
        this.formData.arrivalPlaceCode = selectedItem.place_code
        this.formData.arrivalPlaceName = selectedItem.place_name
        this.formData.arrivalPlaceAddress =
          selectedItem.address1 + '' + selectedItem.address2
      }
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetPlace() {
      if (this.placeModalKbn == 1) {
        this.formData.departurePlaceId = null
        this.formData.departurePlaceName = null
        this.formData.departurePlaceAddress = null
      } else {
        this.formData.arrivalPlaceId = null
        this.formData.arrivalPlaceCode = null
        this.formData.arrivalPlaceName = null
        this.formData.arrivalPlaceAddress = null
      }
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },
    setInfoMsg(msg) {
      this.$emit('set-info-msg', msg)
    },
    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style lang="scss" scoped>
.detailForm {
  height: 500px;
}

#BaseView {
  height: 730px;
}

.edit-button {
  margin-right: 8px;
  margin-bottom: 5px;
  margin-top: 5px;
}
// カレンダー表示切れないように一覧高さの最小値を設定
.cost-tab-pane {
  min-height: 340px;
}

.b-table-sticky-header {
  max-height: inherit;
}
// ※金額テーブル高さ属性が以下のように最大300pxに設定されている（bootstrap?）
// b-table-sticky-header { max-height: 300px }
// class="table table-bordered base-table b-table-sticky-header table-responsive">
//<table data-class="base-table" role="table" aria-busy="false" aria-colcount="7"
//aria-multiselectable="false"
//class="table b-table table-hover table-bordered table-sm border
//b-table-no-border-collapse b-table-selectable
//b-table-select-single b-table-selecting" id="__BVID__7086">
</style>
