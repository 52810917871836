import { createAuthenticaionService, makeQueryParams } from '@/services/index'

const BASE_URL = `${process.env.VUE_APP_API_URL}accounting/`

class PaymentService {
  getAll() {
    return createAuthenticaionService(BASE_URL).get('/')
  }

  get(id) {
    return createAuthenticaionService(BASE_URL).get(`/${id}`)
  }

  create(data) {
    return createAuthenticaionService(BASE_URL).post('/', data)
  }

  update(id, data) {
    return createAuthenticaionService(BASE_URL).put(`/${id}`, data)
  }

  removeList(data) {
    return createAuthenticaionService(`${BASE_URL}removelist/`).put('/', data)
  }

  search(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}search/`).get(`/?${param}`)
  }

  /**
   * 確定
   */
  confirm(id, data) {
    return createAuthenticaionService(`${BASE_URL}commit/`).post(`/${id}`, data)
  }

  /**
   * 一括確定
   */
  allConfirm(ids) {
    return createAuthenticaionService(`${BASE_URL}allcommit/`).post(`/${ids}`)
  }

  /**
   * 承認
   */
  approve(id, data) {
    return createAuthenticaionService(`${BASE_URL}approved/`).post(
      `/${id}`,
      data
    )
  }

  /**
   * 一括承認
   */
  batchApprove(ids) {
    return createAuthenticaionService(`${BASE_URL}batch_approved/`).post(
      `/${ids}`
    )
  }

  /**
   * 一括変更ロック #2781
   */
  batchLock(ids) {
    return createAuthenticaionService(`${BASE_URL}batch_lock/`).post(`/${ids}`)
  }

  /**
   * 一括ロック解除 #2781
   */
  batchUnlock(ids) {
    return createAuthenticaionService(`${BASE_URL}batch_unlock/`).post(
      `/${ids}`
    )
  }

  /**
   * 差し戻し
   * @param {*} id
   * @param {*} data
   */
  remand(id, data) {
    return createAuthenticaionService(`${BASE_URL}sendback/`).post(
      `/${id}`,
      data
    )
  }

  // 更新ロック
  lock(id, data) {
    return createAuthenticaionService(`${BASE_URL}updateLock/`).put(
      `/${id}`,
      data
    )
  }

  // 運賃計算
  freightCalc(cost_type, data) {
    return createAuthenticaionService(`${BASE_URL}fareinfo/`).post(
      `/${cost_type}`,
      data
    )
  }

  //OBIC送信情報作成
  makeObicInfo(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}obicInfo/`).post(`/?${param}`)
  }

  //TSVファイルダウンロード
  downloadTsvFile(fileName) {
    return createAuthenticaionService(`${BASE_URL}downloadFile/`).get(
      `/?${fileName}`
    )
  }

  //CSVファイルアップロード
  upload(data) {
    return createAuthenticaionService(`${BASE_URL}upload/`).post('/', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  //CSVファイルダウンロード
  download(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}download/`).get(`/?${param}`)
  }
}

export default new PaymentService()
