import {
  openMsgModal,
  openSubModal,
  formatYYYYMMDD,
  convertSnakeCace,
  convertCamelCace,
  getMasterName,
  displayYYYYYMMDDHHmmss,
  formatYYYYYMMDDHHmmss,
  getCostWithoutTax,
} from '@/common/Common.js'
import {
  MSG_TYPE,
  DETAIL_SCREEN_KBN,
  GO_OR_BACK,
  IS_MULTIPLE_LOADING,
  RECORD_WORK_KIND,
  MASTER_CODE,
  COST_STATUS,
  MANUAL_INPUT,
  COST_TYPE,
  SEND_FLG,
  VISIBILITY,
  REGISTERING,
} from '@/common/const.js'
import PaymentService from './PaymentService'
import _ from 'lodash'
import { isNil, isEmpty } from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    PaymentService.get(obj.accountingId)
      .then(
        (response) => {
          obj.formData = response.data

          obj.inputFlgPayment = obj.formData.manualInput
          obj.inputFlgBilling = obj.formData.manualInput
          obj.formData.approvalDate = displayYYYYYMMDDHHmmss(
            obj,
            obj.formData.approvalDate
          )
          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            // COPYクリア処理
            obj.formData.accountingId = null
            obj.formData.registrationTimeDisplay = null
            obj.formData.registrationUser = null
            obj.formData.updateTimeDisplay = null
            obj.formData.updateUser = null
            // 請求支払ステータス、請求支払ステータス名、承認依頼コメント、差し戻しコメント、承認日、承認者名
            obj.formData.costStatus = COST_STATUS.INTERLOCK_UNLOCK //インターロック解除
            obj.formData.costStatusName = getMasterName(
              MASTER_CODE.COST_STATUS,
              COST_STATUS.INTERLOCK_UNLOCK
            )
            obj.formData.approvalRequestComment = null
            obj.formData.remandComment = null
            obj.formData.approvalDate = null
            obj.formData.approvalUser = null
            obj.formData.approvalUserName = null
            obj.formData.sendFlg = SEND_FLG.NOT_SENDING
            obj.formData.sendFlgName = getMasterName(
              MASTER_CODE.SEND_FLG,
              SEND_FLG.NOT_SENDING
            )
            if (obj.formData.billingOriginDto != null) {
              obj.formData.billingOriginDto.sendFlg = SEND_FLG.NOT_SENDING
            }
            if (obj.formData.paymentDto != null) {
              obj.formData.paymentDto.sendFlg = SEND_FLG.NOT_SENDING
            }
            //入力状態
            obj.formData.registering = REGISTERING.DEFAULT //未設定
            obj.formData.registeringName = getMasterName(
              MASTER_CODE.REGISTERING,
              REGISTERING.DEFAULT
            )
          } else {
            obj.formData.accountingId = obj.accountingId
          }

          displayFormData(obj)
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  } else {
    //DETAIL_SCREEN_KBN.NEW
    obj.formData.accountingId = null

    // 初期表示
    // 入力状態(0:未設定)
    obj.formData.registering = REGISTERING.DEFAULT
    // 往復区分（0:片道）
    obj.formData.onewayOrRound = GO_OR_BACK.GOWAY
    // 二台積区分（0:1台積）
    obj.formData.isMultipleLoading = IS_MULTIPLE_LOADING.LOAD_ONE
    // 実績登録作業区分（1:積）
    obj.formData.recordWorkKind = RECORD_WORK_KIND.LOAD

    obj.formData.billingCostDtos = []
    obj.formData.paymentCostDtos = []
    // 削除用情報格納
    obj.oriTableItemsBilling = []
    obj.oriTableItemsPayment = []
  }
}

function displayFormData(obj) {
  convertDtoToFormData(
    obj,
    obj.formData.billingOriginDto,
    obj.formData.paymentDto
  )

  // コピー
  if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
    // id null setting
    obj.formData.billingCostDtos.map(function (x) {
      x.costId = null
    })
    obj.formData.paymentCostDtos.map(function (x) {
      x.costId = null
    })
    obj.formData.billingId = null
    obj.formData.paymentId = null
  }

  let billingCostDtos = changeSnakeCase(obj.formData.billingCostDtos)
  obj.formData.billingCostDtos = billingCostDtos
  obj.tableItemsBilling = obj.formData.billingCostDtos

  let paymentCostDtos = changeSnakeCase(obj.formData.paymentCostDtos)
  obj.formData.paymentCostDtos = paymentCostDtos
  obj.tableItemsPayment = obj.formData.paymentCostDtos

  obj.oriData = _.cloneDeep(obj.formData)
  // 削除用情報格納
  obj.oriTableItemsBilling = []
  obj.oriTableItemsPayment = []
  // 元情報格納
  obj.originalTableItemsBilling = _.cloneDeep(obj.tableItemsBilling)
  obj.originalTableItemsPayment = _.cloneDeep(obj.tableItemsPayment)
}

export async function goRegister(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  if (inputValidate(obj) == false) {
    return
  }

  obj.formData.approvalDate = formatYYYYYMMDDHHmmss(obj.formData.approvalDate)

  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.register.label
  )
  if (obj.formData.deleted == '1') {
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG905Q',
      obj.detailCtrlInfo.register.label
    )
  }
  openSubModal(obj, 'register-modal')
}

function setManualInput(obj) {
  let inputFlg = MANUAL_INPUT.MANUAL
  if (
    obj.inputFlgBilling == MANUAL_INPUT.AUTO &&
    obj.inputFlgPayment == MANUAL_INPUT.AUTO
  ) {
    inputFlg = MANUAL_INPUT.AUTO
  }
  return inputFlg
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  // 運賃手入力区分再設定
  obj.formData.manualInput = setManualInput(obj)

  let newFormData = convertFormDataToAccountingDto(obj)

  //税抜き金額チェック
  let isNullchek = isNullCostWithoutTax(obj, newFormData)
  if (!isNullchek) {
    return false
  }

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = PaymentService.create(newFormData)
  } else {
    // 詳細
    callApi = PaymentService.update(obj.accountingId, newFormData)
  }
  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

function inputValidate(obj) {
  //-----------------------
  // 請求情報チェック
  //-----------------------
  // 請求先部署名、請求日入力チェック
  if (inputCheckBillingItems(obj) == false) {
    return false
  }

  // 請求情報なしかつ請求一覧有りのエラーメッセージ表示
  if (obj.tableItemsBilling.length > 0) {
    if (isNullBillingItems(obj) == true) {
      // %sを入力してください。
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage'](
          'YZ00MG984E',
          obj.detailInfo.tab2.label
        )
      )
      return false
    }
  }

  //-----------------------
  // 支払情報チェック
  //-----------------------
  // 運送会社名、支払日入力チェック
  if (inputCheckPaymentItems(obj) == false) {
    return false
  }

  // 支払情報なしかつ支払一覧有りのエラーメッセージ表示
  if (obj.tableItemsPayment.length > 0) {
    if (isNullPaymentItems(obj) == true) {
      // %sを入力してください。
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage'](
          'YZ00MG984E',
          obj.detailInfo.tab3.label
        )
      )
      return false
    }
  }

  // 編集の時、（billingIdまたはpaymentIdがある）キー情報が有り、入力情報なしとエラーメッセージ表示
  if (obj.screenKbn == DETAIL_SCREEN_KBN.DETAIL) {
    if (
      obj.formData.billingId != null &&
      isNullBillingItems(obj) == true &&
      obj.tableItemsBilling.length == 0
    ) {
      // %sを入力してください。
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage'](
          'YZ00MG984E',
          obj.detailInfo.tab2.label
        )
      )
      return false
    }

    if (
      obj.formData.paymentId != null &&
      isNullPaymentItems(obj) == true &&
      obj.tableItemsPayment.length == 0
    ) {
      // %sを入力してください。
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage'](
          'YZ00MG984E',
          obj.detailInfo.tab2.label
        )
      )
      return false
    }
  }
  return true
}

function isNullBillingItems(obj) {
  let isNull = false
  if (
    obj.formData.billingDepartmentId == null &&
    (obj.formData.billingDate == null || obj.formData.billingDate == '')
  ) {
    isNull = true
  }
  return isNull
}

function isNullPaymentItems(obj) {
  let isNull = false
  if (
    obj.formData.paymentCarrierId == null &&
    (obj.formData.paymentDate == null || obj.formData.paymentDate == '')
  ) {
    isNull = true
  }
  return isNull
}

function isNullCostWithoutTax(obj, newFormData) {
  let isNullcostWithoutTax = false
  newFormData.paymentCostDtos.forEach((element) => {
    if (element.costWithoutTax == null) {
      isNullcostWithoutTax = true
    }
  })
  if (!isNullcostWithoutTax) {
    newFormData.billingCostDtos.forEach((element) => {
      if (element.costWithoutTax == null) {
        isNullcostWithoutTax = true
      }
    })
  }

  //税抜き金額 = null の場合
  if (isNullcostWithoutTax) {
    let msg = obj.$store.getters['init/getMessage']('YZ00MG995E')
    openMsgModal(obj, MSG_TYPE.E, msg)
    return false
  }

  return true
}
/**
 * 請求先部署名、請求日 いずれかの入力チェック
 * @param {*} obj
 */
function inputCheckBillingItems(obj) {
  // 請求先部署名 入力有り、請求日 入力なし
  if (
    obj.formData.billingDepartmentId != null &&
    (obj.formData.billingDate == null || obj.formData.billingDate == '')
  ) {
    // %sを入力してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage'](
        'YZ00MG984E',
        obj.detailInfo.billing_date.label
      )
    )
    return false
  }

  // 請求先部署名 入力なし、請求日 入力有り
  if (
    obj.formData.billingDepartmentId == null &&
    obj.formData.billingDate != null &&
    obj.formData.billingDate != ''
  ) {
    // %sを入力してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage'](
        'YZ00MG984E',
        obj.detailInfo.billing_department_code.label
      )
    )
    return false
  }
  return true
}

/**
 * 運送会社名、支払日 いずれかの入力チェック
 * @param {*} obj
 */
function inputCheckPaymentItems(obj) {
  // 運送会社名 入力有り、支払日 入力なし
  if (
    obj.formData.paymentCarrierId != null &&
    (obj.formData.paymentDate == null || obj.formData.paymentDate == '')
  ) {
    // %sを入力してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage'](
        'YZ00MG984E',
        obj.detailInfo.payment_date.label
      )
    )
    return false
  }

  // 運送会社名 入力なし、支払日 入力有り
  if (
    obj.formData.paymentCarrierId == null &&
    obj.formData.paymentDate != null &&
    obj.formData.paymentDate != ''
  ) {
    // %sを入力してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage'](
        'YZ00MG984E',
        obj.detailInfo.payment_carrier_code.label
      )
    )
    return false
  }
  return true
}

function convertFormDataToAccountingDto(obj) {
  let newFormData = _.cloneDeep(obj.formData)
  newFormData.departureDate = formatYYYYMMDD(obj, newFormData.departureDate)
  newFormData.arrivalDate = formatYYYYMMDD(obj, newFormData.arrivalDate)
  newFormData.workActDate = formatYYYYMMDD(obj, newFormData.workActDate)
  newFormData.billingDate = formatYYYYMMDD(obj, newFormData.billingDate)
  newFormData.paymentDate = formatYYYYMMDD(obj, newFormData.paymentDate)

  newFormData.billingOriginDto = convertFormDataToBillingOriginDto(newFormData)
  newFormData.paymentDto = convertFormDataToPaymentDto(newFormData)

  // 新規・コピーの時、項目情報未入力かつ金額一覧0件の時、Dtoはnullで設定
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    if (isNullBillingItems(obj) == true && obj.tableItemsBilling.length == 0) {
      newFormData.billingOriginDto = null
    }

    if (isNullPaymentItems(obj) == true && obj.tableItemsPayment.length == 0) {
      newFormData.paymentDto = null
    }
  }
  // 編集の時、（新規登録時billingOriginDtoがnull）null状態で登録する時、Dtoはnullで設定
  // billingIdまたはpaymentIdが存在する場合は、チェックが必要
  if (obj.screenKbn == DETAIL_SCREEN_KBN.DETAIL) {
    if (
      newFormData.billingId == null &&
      isNullBillingItems(obj) == true &&
      obj.tableItemsBilling.length == 0
    ) {
      newFormData.billingOriginDto = null
    }

    if (
      newFormData.paymentId == null &&
      isNullPaymentItems(obj) == true &&
      obj.tableItemsPayment.length == 0
    ) {
      newFormData.paymentDto = null
    }
  }

  // 画面表示情報＋削除情報
  newFormData.billingCostDtos = mergeCostDto(
    obj.oriTableItemsBilling,
    newFormData.billingCostDtos
  )
  newFormData.paymentCostDtos = mergeCostDto(
    obj.oriTableItemsPayment,
    newFormData.paymentCostDtos
  )
  newFormData.billingCostDtos = convertCamelCace(newFormData.billingCostDtos)
  newFormData.paymentCostDtos = convertCamelCace(newFormData.paymentCostDtos)

  // if (newFormData.deleted == '1') {
  //   newFormData.deleted = '0'
  // }
  return newFormData
}

/**
 * 運賃計算
 * @param {*} costType 請求支払い区分 '0': 請求, '1': 支払
 * @param {*} obj
 */
export function freightCalc(costType, obj) {
  obj.$bvModal.hide('register-modal')

  const validated = obj.$refs['tab-group'].$refs['base-tab-group'].validate()
  if (!validated) return

  let newFormData = convertFormDataToAccountingDto(obj)

  obj.$store.dispatch('init/setServiceLoading')
  PaymentService.freightCalc(costType, newFormData)
    .then(
      (response) => {
        // response.data型=List<CostDto>
        let costDtos = changeSnakeCase(response.data)
        if (costType == COST_TYPE.BILLING) {
          // 請求
          obj.formData.billingCostDtos = addCostDto(
            obj.formData.billingCostDtos,
            costDtos
          )
          obj.tableItemsBilling = obj.formData.billingCostDtos
          obj.inputFlgBilling = MANUAL_INPUT.AUTO
        } else if (costType == COST_TYPE.PAYMENT) {
          // 支払
          obj.formData.paymentCostDtos = addCostDto(
            obj.formData.paymentCostDtos,
            costDtos
          )
          obj.tableItemsPayment = obj.formData.paymentCostDtos
          obj.inputFlgPayment = MANUAL_INPUT.AUTO
        }
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

//運賃計算結果反映（新しい運賃項目のみ追加） #2761
function addCostDto(oldCostDtos, costDtos) {
  let newCostDtos = oldCostDtos
  costDtos.forEach((element) => {
    if (
      oldCostDtos.some(
        (list) => list.freight_item_id == element.freight_item_id
      )
    ) {
      //exists -> Update
      newCostDtos.forEach((oldElement) => {
        if (oldElement.freight_item_id === element.freight_item_id) {
          oldElement.amount_money = element.amount_money
          oldElement.cost_without_tax = element.cost_without_tax
          oldElement.freight_item_name = element.freight_item_name
          oldElement.tariff_no = element.tariff_no
        }
      })
    } else {
      //add
      newCostDtos.push(element)
    }
  })

  return newCostDtos
}
function mergeCostDto(deleteItems, tableItems) {
  if (deleteItems.length > 0) {
    // 画面表示情報＋削除情報
    for (let info of deleteItems) {
      tableItems.push(_.cloneDeep(info))
    }
  }
  return tableItems
}

export async function goConfirm(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  if (inputValidate(obj) == false) {
    return
  }

  //入力状態が完了ではない場合, エラー
  if (obj.formData.registering != REGISTERING.DEFAULT) {
    let msg = obj.$store.getters['init/getMessage']('YZ00MG972E')
    openMsgModal(obj, MSG_TYPE.E, msg)
    return
  }

  obj.formData.approvalDate = formatYYYYYMMDDHHmmss(obj.formData.approvalDate)
  if (obj.formData.deleted == '1') {
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG905Q',
      obj.detailCtrlInfo.register.label
    )
  }

  obj.btnMsgLabel = obj.detailCtrlInfo.confirm.label
  // %s処理を行います。よろしいですか？
  obj.confirmMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.btnMsgLabel
  )
  openSubModal(obj, 'confirm-modal')
}

export async function goApprove(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  if (inputValidate(obj) == false) {
    return
  }

  obj.formData.approvalDate = formatYYYYYMMDDHHmmss(obj.formData.approvalDate)
  if (obj.formData.deleted == '1') {
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG905Q',
      obj.detailCtrlInfo.register.label
    )
  }

  obj.btnMsgLabel = obj.detailCtrlInfo.approve.label
  // %s処理を行います。よろしいですか？
  obj.approveMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.btnMsgLabel
  )
  openSubModal(obj, 'approve-modal')
}

export function goLock(obj) {
  obj.btnMsgLabel = obj.detailCtrlInfo.lock.label
  // %s処理を行います。よろしいですか？
  obj.lockMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.btnMsgLabel
  )
  openSubModal(obj, 'lock-modal')
}

export function goRemand(obj) {
  // 差し戻しコメント必須チェック
  if (
    isNil(obj.formData.remandComment) ||
    isEmpty(obj.formData.remandComment)
  ) {
    // 警告モーダル表示
    // %sを入力してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage'](
        'YZ00MG984E',
        obj.detailInfo.remand_comment_code.label
      )
    )
    return
  }

  obj.btnMsgLabel = obj.detailCtrlInfo.remand.label
  // %s処理を行います。よろしいですか？
  obj.remandMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.btnMsgLabel
  )
  openSubModal(obj, 'remand-modal')
}

/**
 * 確定
 * @param {*} obj
 */
export async function confirm(obj) {
  obj.$bvModal.hide('confirm-modal')
  // let approvalRequestComment
  // if (
  //   isNil(obj.formData.approvalRequestComment) ||
  //   isEmpty(obj.formData.approvalRequestComment)
  // ) {
  //   approvalRequestComment = ''
  // } else {
  //   approvalRequestComment = obj.formData.approvalRequestComment
  // }
  //let newData = { approvalRequestComment: approvalRequestComment }
  let newFormData = convertFormDataToAccountingDto(obj)
  //税抜き金額チェック
  let isNullchek = isNullCostWithoutTax(obj, newFormData)
  if (!isNullchek) {
    return false
  }

  obj.$store.dispatch('init/setServiceLoading')
  PaymentService.confirm(obj.accountingId, newFormData)
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'detail-info2-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

/**
 * 承認
 * @param {*} obj
 */
export async function approve(obj) {
  obj.$bvModal.hide('approve-modal')
  let newFormData = convertFormDataToAccountingDto(obj)
  //税抜き金額チェック
  let isNullchek = isNullCostWithoutTax(obj, newFormData)
  if (!isNullchek) {
    return false
  }
  obj.$store.dispatch('init/setServiceLoading')
  PaymentService.approve(obj.accountingId, newFormData)
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'detail-info2-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

/**
 * 更新ロック
 * @param {*} obj
 */
export function lock(obj) {
  obj.$bvModal.hide('lock-modal')
  let newFormData = convertFormDataToAccountingDto(obj)
  obj.$store.dispatch('init/setServiceLoading')
  PaymentService.lock(obj.accountingId, newFormData)
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'detail-info2-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

/**
 * 差し戻し
 * @param {*} obj
 */
export function remand(obj) {
  obj.$bvModal.hide('remand-modal')
  let newFormData = convertFormDataToAccountingDto(obj)
  obj.$store.dispatch('init/setServiceLoading')
  PaymentService.remand(obj.accountingId, newFormData)
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'detail-info2-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function clear(obj) {
  obj.formData = _.cloneDeep(obj.oriData)
  obj.tableItemsBilling = _.cloneDeep(obj.originalTableItemsBilling)
  obj.tableItemsPayment = _.cloneDeep(obj.originalTableItemsPayment)
  // 削除用情報格納
  obj.oriTableItemsBilling = []
  obj.oriTableItemsPayment = []

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

//請求（編集）
export function billingEditRow(obj) {
  obj.tabKbn = 2
  // パラメータ設定
  obj.editModalData = {}
  obj.editModalData.detailScreenKbn = DETAIL_SCREEN_KBN.DETAIL
  obj.editModalData.tabKbn = 2
  obj.editModalData.selectedDetail = _.cloneDeep(obj.selectedBilling)
  //#2786 部署グループ
  obj.editModalData.ownerDepartmentGroup = obj.formData.ownerDepartmentGroup
  openSubModal(obj, 'amount-edit-modal')
}

//請求（新規）
export function billingAddRow(obj) {
  obj.detailScreenKbnBilling = DETAIL_SCREEN_KBN.NEW
  obj.tabKbn = 2
  // パラメータ設定
  obj.editModalData = {}
  obj.editModalData.detailScreenKbn = DETAIL_SCREEN_KBN.NEW
  obj.editModalData.tabKbn = 2
  obj.editModalData.selectedDetail = null
  //#2786 部署グループ
  obj.editModalData.ownerDepartmentGroup = obj.formData.ownerDepartmentGroup
  openSubModal(obj, 'amount-edit-modal')
}

export function billingCalcRow(obj) {
  // 請求支払い区分 '0': 請求, '1': 支払
  freightCalc(COST_TYPE.BILLING, obj)
}

export function billingRemoveRow(obj) {
  for (let info of obj.selectedBilling) {
    const index = obj.tableItemsBilling.indexOf(info)
    if (info.cost_id != null) {
      // DB登録されている行に対して「削除」ボタン押下した場合
      obj.oriTableItemsBilling.push(_.cloneDeep(info))
      let oriIndex = obj.oriTableItemsBilling.length - 1
      obj.oriTableItemsBilling[oriIndex].deleted = 1
      obj.tableItemsBilling.splice(index, 1)
    } else if (info.cost_id == null) {
      // 「新規登録」ボタンから追加した行に対して「削除」ボタン押下した場合
      obj.tableItemsBilling.splice(index, 1)
    }
  }
  obj.formData.billingOriginDtos = obj.tableItemsBilling
  obj.inputFlgBilling = MANUAL_INPUT.MANUAL
}

//支払（新規）
export function paymentAddRow(obj) {
  obj.tabKbn = 3
  // パラメータ設定
  obj.editModalData = {}
  obj.editModalData.detailScreenKbn = DETAIL_SCREEN_KBN.NEW
  obj.editModalData.tabKbn = 3
  //obj.editModalData.selectedDetail = _.cloneDeep(obj.selectedPayment)
  obj.editModalData.selectedDetail = null
  //#2786 部署グループ
  obj.editModalData.ownerDepartmentGroup = obj.formData.ownerDepartmentGroup
  openSubModal(obj, 'amount-edit-modal')
}

//支払（編集）
export function paymentEditRow(obj) {
  obj.tabKbn = 3
  // パラメータ設定
  obj.editModalData = {}
  obj.editModalData.detailScreenKbn = DETAIL_SCREEN_KBN.DETAIL
  obj.editModalData.tabKbn = 3
  obj.editModalData.selectedDetail = _.cloneDeep(obj.selectedPayment)
  //#2786 部署グループ
  obj.editModalData.ownerDepartmentGroup = obj.formData.ownerDepartmentGroup
  openSubModal(obj, 'amount-edit-modal')
}

export function paymentCalcRow(obj) {
  // 請求支払い区分 '0': 請求, '1': 支払
  freightCalc(COST_TYPE.PAYMENT, obj)
}

export function paymentRemoveRow(obj) {
  for (let info of obj.selectedPayment) {
    const index = obj.tableItemsPayment.indexOf(info)
    if (info.cost_id != null) {
      // DB登録されている行に対して「削除」ボタン押下した場合
      obj.oriTableItemsPayment.push(_.cloneDeep(info))
      let oriIndex = obj.oriTableItemsPayment.length - 1
      obj.oriTableItemsPayment[oriIndex].deleted = 1
      obj.tableItemsPayment.splice(index, 1)
    } else if (info.cost_id == null) {
      // 「新規登録」ボタンから追加した行に対して「削除」ボタン押下した場合
      obj.tableItemsPayment.splice(index, 1)
    }
  }
  obj.formData.paymentCostDtos = obj.tableItemsPayment
  obj.inputFlgPayment = MANUAL_INPUT.MANUAL
}

export function afterCloseSetAmountEditInfo(obj, info) {
  //税抜き金額
  let costWithoutTax = getCostWithoutTax(info.amountMoney, info.taxPercentage)
  //「編集」ボタン
  if (info.detailScreenKbn === DETAIL_SCREEN_KBN.DETAIL) {
    if (info.tabKbn === 2) {
      // 「新規登録」ボタンから追加した行に対して「編集」ボタン押下した場合
      obj.selectedBilling[0].freight_item_id = info.freightItemId
      obj.selectedBilling[0].freight_item_name = info.freightItemName
      obj.selectedBilling[0].amount_money = info.amountMoney
      obj.selectedBilling[0].tax_percentage = info.taxPercentage
      obj.selectedBilling[0].cost_without_tax = costWithoutTax
      obj.formData.paymentCostDtos = obj.tableItemsPayment
      obj.inputFlgBilling = MANUAL_INPUT.MANUAL
    }
    if (info.tabKbn === 3) {
      // 「新規登録」ボタンから追加した行に対して「編集」ボタン押下した場合
      obj.selectedPayment[0].freight_item_id = info.freightItemId
      obj.selectedPayment[0].freight_item_name = info.freightItemName
      obj.selectedPayment[0].amount_money = info.amountMoney
      obj.selectedPayment[0].tax_percentage = info.taxPercentage
      obj.selectedPayment[0].cost_without_tax = costWithoutTax
      obj.formData.paymentCostDtos = obj.tableItemsPayment
      obj.inputFlgPayment = MANUAL_INPUT.MANUAL
    }
  } else if (info.detailScreenKbn === DETAIL_SCREEN_KBN.NEW) {
    //「追加」ボタン
    let addAmount = {
      cost_id: null,
      cost_relation_id: null,
      planning_result_id: null,
      relation_id: null,
      relation_type: null,
      //運賃項目
      freight_item_id: info.freightItemId,
      freight_item_name: info.freightItemName,
      //税率
      tax_percentage: info.taxPercentage,
      tariff_no: null,
      note: null,
      //金額
      amount_money: info.amountMoney,
      //税抜き金額
      cost_without_tax: costWithoutTax,
      deleted: 0,
      registration_time: null,
      registration_user: null,
      update_by_trigger: null,
      update_time: null,
      update_user: null,
      version: null,
    }
    if (info.tabKbn === 2) {
      obj.formData.billingCostDtos.push(addAmount)
      obj.tableItemsBilling = obj.formData.billingCostDtos
      obj.inputFlgBilling = MANUAL_INPUT.MANUAL
    }
    if (info.tabKbn === 3) {
      obj.formData.paymentCostDtos.push(addAmount)
      obj.tableItemsPayment = obj.formData.paymentCostDtos
      obj.inputFlgPayment = MANUAL_INPUT.MANUAL
    }
  }
}

function convertDtoToFormData(obj, billingOriginDto, paymentDto) {
  if (billingOriginDto != null) {
    obj.formData.billingId = billingOriginDto.billingId
    obj.formData.billingBlockCode = billingOriginDto.blockCode
    obj.formData.billingDepartmentId = billingOriginDto.billingDepartmentId
    obj.formData.billingDepartmentCode = billingOriginDto.billingDepartmentCode
    obj.formData.billingDepartmentName = billingOriginDto.billingDepartmentName
    obj.formData.billingDate = billingOriginDto.billingDate
    obj.formData.invoiceDepartmentCode = billingOriginDto.invoiceDepartmentCode
    obj.formData.salesDepartmentCode = billingOriginDto.salesDepartmentCode
    obj.formData.inputStaffId = billingOriginDto.inputStaffId
    obj.formData.salesStaffId = billingOriginDto.salesStaffId
    obj.formData.billingReserve01 = billingOriginDto.reserve01
    obj.formData.billingReserve02 = billingOriginDto.reserve02
    obj.formData.billingReserve03 = billingOriginDto.reserve03
    obj.formData.billingReserve04 = billingOriginDto.reserve04
    obj.formData.billingReserve05 = billingOriginDto.reserve05
    obj.formData.billingReserve06 = billingOriginDto.reserve06
    obj.formData.billingReserve07 = billingOriginDto.reserve07
    obj.formData.billingReserve08 = billingOriginDto.reserve08
    obj.formData.billingReserve09 = billingOriginDto.reserve09
    obj.formData.billingReserve10 = billingOriginDto.reserve10
    obj.formData.billingSendFlg = billingOriginDto.sendFlg
    obj.formData.billingDeleted = billingOriginDto.deleted
    obj.formData.billingRegistrationTimeDisplay =
      billingOriginDto.registrationTimeDisplay
    obj.formData.billingRegistrationUser = billingOriginDto.registrationUser
    obj.formData.billingUpdateTimeDisplay = billingOriginDto.updateTimeDisplay
    obj.formData.billingUpdateUser = billingOriginDto.updateUser
    obj.formData.billingVersion = billingOriginDto.version
    obj.formData.billingUpdateByTrigger = billingOriginDto.updateByTrigger
  }

  if (paymentDto != null) {
    obj.formData.paymentId = paymentDto.paymentId
    obj.formData.paymentBlockCode = paymentDto.blockCode
    obj.formData.paymentCarrierId = paymentDto.carrierId
    obj.formData.paymentCarrierCode = paymentDto.carrierCode
    obj.formData.paymentCarrierName = paymentDto.carrierName
    obj.formData.paymentDate = paymentDto.paymentDate
    obj.formData.paymentReserve01 = paymentDto.reserve01
    obj.formData.paymentReserve02 = paymentDto.reserve02
    obj.formData.paymentReserve03 = paymentDto.reserve03
    obj.formData.paymentReserve04 = paymentDto.reserve04
    obj.formData.paymentReserve05 = paymentDto.reserve05
    obj.formData.paymentReserve06 = paymentDto.reserve06
    obj.formData.paymentReserve07 = paymentDto.reserve07
    obj.formData.paymentReserve08 = paymentDto.reserve08
    obj.formData.paymentReserve09 = paymentDto.reserve09
    obj.formData.paymentReserve10 = paymentDto.reserve10
    obj.formData.paymentSendFlg = paymentDto.sendFlg
    obj.formData.paymentDeleted = paymentDto.deleted
    obj.formData.paymentRegistrationTimeDisplay =
      paymentDto.registrationTimeDisplay
    obj.formData.paymentRegistrationUser = paymentDto.registrationUser
    obj.formData.paymentUpdateTimeDisplay = paymentDto.updateTimeDisplay
    obj.formData.paymentUpdateUser = paymentDto.updateUser
    obj.formData.paymentVersion = paymentDto.version
    obj.formData.paymentUpdateByTrigger = paymentDto.updateByTrigger
  }
}

function convertFormDataToBillingOriginDto(newFormData) {
  let billingOriginDto = {}
  billingOriginDto.billingId = newFormData.billingId
  billingOriginDto.blockCode = newFormData.billingBlockCode
  billingOriginDto.billingDepartmentId = newFormData.billingDepartmentId
  billingOriginDto.billingDepartmentCode = newFormData.billingDepartmentCode
  billingOriginDto.billingDepartmentName = newFormData.billingDepartmentName
  billingOriginDto.billingDate = newFormData.billingDate
  billingOriginDto.invoiceDepartmentCode = newFormData.invoiceDepartmentCode
  billingOriginDto.salesDepartmentCode = newFormData.salesDepartmentCode
  billingOriginDto.salesStaffId = newFormData.salesStaffId
  billingOriginDto.inputStaffId = newFormData.inputStaffId
  billingOriginDto.reserve01 = newFormData.billingReserve01
  billingOriginDto.reserve02 = newFormData.billingReserve02
  billingOriginDto.reserve03 = newFormData.billingReserve03
  billingOriginDto.reserve04 = newFormData.billingReserve04
  billingOriginDto.reserve05 = newFormData.billingReserve05
  billingOriginDto.reserve06 = newFormData.billingReserve06
  billingOriginDto.reserve07 = newFormData.billingReserve07
  billingOriginDto.reserve08 = newFormData.billingReserve08
  billingOriginDto.reserve09 = newFormData.billingReserve09
  billingOriginDto.reserve10 = newFormData.billingReserve10
  billingOriginDto.sendFlg = newFormData.billingSendFlg
  // ベース情報
  billingOriginDto.deleted = newFormData.billingDeleted
  billingOriginDto.registrationTimeDisplay =
    newFormData.billingRegistrationTimeDisplay
  billingOriginDto.registrationUser = newFormData.billingRegistrationUser
  billingOriginDto.updateTimeDisplay = newFormData.billingUpdateTimeDisplay
  billingOriginDto.updateUser = newFormData.billingUpdateUser
  billingOriginDto.version = newFormData.billingVersion
  billingOriginDto.updateByTrigger = newFormData.billingUpdateByTrigger

  return billingOriginDto
}
function convertFormDataToPaymentDto(newFormData) {
  let paymentDto = {}
  paymentDto.paymentId = newFormData.paymentId
  paymentDto.blockCode = newFormData.paymentBlockCode
  paymentDto.carrierId = newFormData.paymentCarrierId
  paymentDto.carrierCode = newFormData.paymentCarrierCode
  paymentDto.carrierName = newFormData.paymentCarrierName
  paymentDto.paymentDate = newFormData.paymentDate
  paymentDto.reserve01 = newFormData.paymentReserve01
  paymentDto.reserve02 = newFormData.paymentReserve02
  paymentDto.reserve03 = newFormData.paymentReserve03
  paymentDto.reserve04 = newFormData.paymentReserve04
  paymentDto.reserve05 = newFormData.paymentReserve05
  paymentDto.reserve06 = newFormData.paymentReserve06
  paymentDto.reserve07 = newFormData.paymentReserve07
  paymentDto.reserve08 = newFormData.paymentReserve08
  paymentDto.reserve09 = newFormData.paymentReserve09
  paymentDto.reserve10 = newFormData.paymentReserve10
  paymentDto.sendFlg = newFormData.paymentSendFlg
  // ベース情報
  paymentDto.deleted = newFormData.paymentDeleted
  paymentDto.registrationTimeDisplay =
    newFormData.paymentRegistrationTimeDisplay
  paymentDto.registrationUser = newFormData.paymentRegistrationUser
  paymentDto.updateTimeDisplay = newFormData.paymentUpdateTimeDisplay
  paymentDto.updateUser = newFormData.paymentUpdateUser
  paymentDto.version = newFormData.paymentVersion
  paymentDto.updateByTrigger = newFormData.paymentUpdateByTrigger
  return paymentDto
}

function changeSnakeCase(items) {
  var datas = convertSnakeCace(items)
  datas.sort(function (a, b) {
    return a.cost_id - b.cost_id
  })
  return datas
}

export function getConstStatus(obj) {
  PaymentService.get(obj.accountingId)
    .then(
      (response) => {
        obj.formData = response.data
        console.log('getConstStatus', obj.formData)
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function setReadOnlyDetailInfo(info) {
  //基本情報
  info.sub_modal_owner.visibility = VISIBILITY.DISABLE
  info.sub_modal_product.visibility = VISIBILITY.DISABLE
  info.quantity.visibility = VISIBILITY.DISABLE
  info.weight.visibility = VISIBILITY.DISABLE
  info.volume.visibility = VISIBILITY.DISABLE
  info.sub_modal_departure_place.visibility = VISIBILITY.DISABLE
  info.arrival_date.visibility = VISIBILITY.DISABLE
  info.departure_date.visibility = VISIBILITY.DISABLE
  info.sub_modal_arrival_place.visibility = VISIBILITY.DISABLE
  info.sub_modal_carrier.visibility = VISIBILITY.DISABLE
  info.sub_modal_vehicle.visibility = VISIBILITY.DISABLE
  info.real_vehicle_kind.visibility = VISIBILITY.DISABLE
  info.sub_modal_real_vehicle.visibility = VISIBILITY.DISABLE
  info.sub_modal_real_trailer.visibility = VISIBILITY.DISABLE
  info.work_act_date.visibility = VISIBILITY.DISABLE
  info.actual_distance.visibility = VISIBILITY.DISABLE
  info.approval_date.visibility = VISIBILITY.DISABLE
  info.approval_user.visibility = VISIBILITY.DISABLE
  info.oneway_or_round.visibility = VISIBILITY.DISABLE
  info.is_multiple_loading.visibility = VISIBILITY.DISABLE
  info.destination_country.visibility = VISIBILITY.DISABLE
  info.record_work_kind.visibility = VISIBILITY.DISABLE

  info.reserve01.visibility = VISIBILITY.DISABLE
  info.reserve02.visibility = VISIBILITY.DISABLE
  info.reserve03.visibility = VISIBILITY.DISABLE
  info.reserve04.visibility = VISIBILITY.DISABLE
  info.reserve05.visibility = VISIBILITY.DISABLE
  info.reserve06.visibility = VISIBILITY.DISABLE
  info.registering.visibility = VISIBILITY.DISABLE
  //請求金額
  info.sub_modal_department_billing.visibility = VISIBILITY.DISABLE
  info.billing_date.visibility = VISIBILITY.DISABLE
  info.invoice_department_code.visibility = VISIBILITY.DISABLE
  info.sales_department_code.visibility = VISIBILITY.DISABLE
  info.billing_reserve01.visibility = VISIBILITY.DISABLE
  info.billing_reserve02.visibility = VISIBILITY.DISABLE
  info.billing_reserve03.visibility = VISIBILITY.DISABLE
  info.billing_reserve04.visibility = VISIBILITY.DISABLE
  info.billing_reserve05.visibility = VISIBILITY.DISABLE
  info.billing_reserve06.visibility = VISIBILITY.DISABLE
  info.billing_reserve07.visibility = VISIBILITY.DISABLE
  info.billing_reserve08.visibility = VISIBILITY.DISABLE
  info.billing_reserve09.visibility = VISIBILITY.DISABLE
  info.billing_reserve10.visibility = VISIBILITY.DISABLE
  info.billing_send_flg.visibility = VISIBILITY.DISABLE
  info.input_staff_id.visibility = VISIBILITY.DISABLE
  info.sales_staff_id.visibility = VISIBILITY.DISABLE

  //支払い金額
  info.sub_modal_carrier_payment.visibility = VISIBILITY.DISABLE
  info.payment_date.visibility = VISIBILITY.DISABLE
  info.payment_reserve01.visibility = VISIBILITY.DISABLE
  info.payment_reserve02.visibility = VISIBILITY.DISABLE
  info.payment_reserve03.visibility = VISIBILITY.DISABLE
  info.payment_reserve04.visibility = VISIBILITY.DISABLE
  info.payment_reserve05.visibility = VISIBILITY.DISABLE
  info.payment_reserve06.visibility = VISIBILITY.DISABLE
  info.payment_reserve07.visibility = VISIBILITY.DISABLE
  info.payment_reserve08.visibility = VISIBILITY.DISABLE
  info.payment_reserve09.visibility = VISIBILITY.DISABLE
  info.payment_reserve10.visibility = VISIBILITY.DISABLE
  info.payment_send_flg.visibility = VISIBILITY.DISABLE

  return info
}
