/**
 * 請求
 */
export default class Billing {
  //--------------------
  // 基本情報タブ
  //--------------------
  //受付番号
  receiptNo
  //荷主名
  ownerDepartmentName
  //摘要01(記名符号)
  description01
  //摘要02(機番)
  description02
  //摘要03(国内海外区分)
  description03
  ///予備12(仕向国コード)
  destinationCountry
  //予備13(仕向国名)
  reserve13
  //出発開始日
  departureDate
  //出発場所ID
  departurePlaceId
  //出発場所名
  departurePlaceName
  //出荷場所住所
  departurePlaceAddress
  //到着開始日
  arrivalDate
  //到着場所ID
  arrivalPlaceId
  //到着場所名
  arrivalPlaceName
  //到着場所住所
  arrivalPlaceAddress
  //商品名
  productName
  //幅
  reserve08
  //高さ
  reserve09
  //長さ
  reserve07
  //オーダ距離
  actualDistance
  //質量
  weight
  //区間分割
  sectionPartitionFlag
  //トラック/トレーラー
  realVehicleKind
  //二台積
  isMultipleLoading
  //往路/復路
  goOrBack
  //請求日
  billingDate
  // 請求支払ID
  accountingId
  // 請求ID
  billingId

  // deleted = '0'
  // registrationTimeDisplay = null
  // registrationUser = null
  // updateTimeDisplay = null
  // updateUser = null
  // version = null
  // updateByTrigger = null

  //--------------------
  // 請求情報タブ
  //--------------------
  billings = []
  costs = []
}
